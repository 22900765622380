export default [
  {
    name: 'CDO',
    link:
      'https://www.kayak.co.uk/Cagayan-de-Oro-Hotels-Red-Planet-Cagayan-De-Oro.569012.ksp',
    image:
      'https://content.r9cdn.net/seo-res/badges/v5/WHITE_MEDIUM_TRAVEL_AWARDS.png',
  },
  {
    name: 'CEB',
    link: 'https://www.kayak.co.uk/Cebu-City-Hotels-Red-Planet-Cebu.439145.ksp',
    image:
      'https://content.r9cdn.net/seo-res/badges/v5/WHITE_MEDIUM_TRAVEL_AWARDS.png',
  },
  {
    name: 'AUR',
    link:
      'https://www.kayak.com/Quezon-City-Hotels-Red-Planet-Aurora-Boulevard.3325160.ksp',
    image:
      'https://content.r9cdn.net/seo-res/badges/v5/WHITE_MEDIUM_TRAVEL_AWARDS.png',
  },
  {
    name: 'AMO',
    link:
      'https://www.kayak.co.uk/Manila-Hotels-Red-Planet-Amorsolo.2332422.ksp',
    image:
      'https://content.r9cdn.net/seo-res/badges/v5/WHITE_MEDIUM_TRAVEL_AWARDS.png',
  },
  {
    name: 'ASE',
    link:
      'https://www.kayak.co.uk/Paranaque-Hotels-Red-Planet-Aseana-City-Manila.760387.ksp',
    image:
      'https://content.r9cdn.net/seo-res/badges/v5/WHITE_MEDIUM_TRAVEL_AWARDS.png',
  },
  {
    name: 'MAN',
    link:
      'https://www.kayak.fr/Manille-Hotels-Red-Planet-Manila-Bay.3241799.ksp',
    image:
      'https://content.r9cdn.net/seo-res/badges/v5/WHITE_MEDIUM_TRAVEL_AWARDS.png',
  },
  {
    name: 'BIN',
    link:
      'https://www.kayak.com/Manila-Hotels-Red-Planet-Manila-Binondo.3241796.ksp',
    image:
      'https://content.r9cdn.net/seo-res/badges/v5/WHITE_MEDIUM_TRAVEL_AWARDS.png',
  },
  {
    name: 'ORT',
    link:
      'https://www.kayak.co.uk/Manila-Hotels-Red-Planet-Manila-Ortigas.2041353.ksp',
    image:
      'https://content.r9cdn.net/seo-res/badges/v5/WHITE_MEDIUM_TRAVEL_AWARDS.png',
  },
  {
    name: 'QUE',
    link:
      'https://www.kayak.co.uk/Quezon-City-Hotels-Red-Planet-Timog-Avenue-Quezon-City-Manila.2006517.ksp',
    image:
      'https://content.r9cdn.net/seo-res/badges/v5/WHITE_MEDIUM_TRAVEL_AWARDS.png',
  },
];
