/**
 * Created by methee on 8/21/17.
 */
/* eslint-disable no-param-reassign, no-shadow, camelcase */

import PropTypes from 'prop-types';
import moment from 'moment';
import queryString from 'query-string';
import cardValidator from 'card-validator';
import gql from 'graphql-tag';
import Cookies from 'universal-cookie';
import {
  createTokenPaymentOmise,
  createTokenPaymentMaya,
} from '../lib/payment';
// import adyenEncrypt from 'adyen-encryption';
// import mp from '../analytic';
// import Inspectlet from '../inspectlet';

import {
  CREATE_BOOKING,
  CHANGE_ROOM,
  JOIN_REMEMBERED,
  AUTO_ASSIGN_GUESTS,
  INCLUDE_BREAKFAST,
  INCLUDE_SMOKING,
  INCLUDE_PET_FRIENDLY,
  ADD_SUNDRY_ITEM,
  FAIL_GUEST_PROFILE,
  SUCCESS_GUEST_PROFILE,
  CHANGE_PAYMENT_METHOD,
  FAIL_CREDIT_CARD,
  SUCCESS_CREDIT_CARD,
  SUCCESS_WALLET,
  FAIL_WALLET,
  APPLY_PROMO_CODE,
  SUCCESS_PROMO_CODE,
  FAIL_PROMO_CODE,
  SUBMIT_BOOKING,
  SUCCESS_SUBMIT_BOOKING,
  FAIL_SUBMIT_BOOKING,
  RESEND_BOOKING_CONFIRMATION,
  SUCCESS_RESEND_BOOKING_CONFIRMATION,
  FAIL_RESEND_BOOKING_CONFIRMATION,
  GET_BOOKINGS_START,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_FAIL,
  DELETE_BOOKINGS_START,
  DELETE_BOOKINGS_FAIL,
  DELETE_BOOKINGS_SUCCESS,
  USER_BOOKING,
  CHANGE_MAYA_PAYMENT_METHOD,
} from '../constants';

import {
  isStringAlpha,
  isEmail,
  inArray,
  isArray,
  addFieldArray,
  isNonLatinCharacters,
} from '../utils';

import { loginWithToken, closeResendBookingModal } from './user';

import Hotel from '../data/types/HotelType';
import Room from '../data/types/RoomType';
import Promotion from '../data/types/ApplyPromotionType';
import BookingType from '../data/types/BookingType';
import SundryItemType from '../data/types/SundryItemType';
import { TYPE_OK, addFlashMessage, TYPE_ERROR } from './flashMessage';

export const PAYMENT_CREDIT_CARD = 'credit_card';
export const PAYMENT_PAY_AT_HOTEL = 'pay_later';
export const PAYMENT_WALLET = 'wallet';
// export const PAYMENT_MAYA_ONLINE = 'maya_online';
export const PAYMENT_MAYA_WALLET = 'maya_wallet';
export const PAYMENT_MAYA_QRPH = 'maya_qrph';

const initialBooking = {
  hotel: null,
  sundrySales: [],
  checkIn: null,
  checkOut: null,
  flashCode: '',
  roomTypes: [],
  numberOfRooms: 0,
  adults: 0,
  children: 0,
  joinRemembered: false,
  supportedPayment: [
    PAYMENT_CREDIT_CARD,
    PAYMENT_PAY_AT_HOTEL,
    PAYMENT_WALLET,
    // PAYMENT_MAYA_ONLINE,
    PAYMENT_MAYA_WALLET,
    PAYMENT_MAYA_QRPH,
  ],
  payment: PAYMENT_CREDIT_CARD,
  creditCard: {
    cardHolder: '',
    cardNumber: '',
    expiry: '',
    cvc: '',
  },
  wallet: {
    source: '',
    phone: '',
  },
  includeBreakfast: false,
  includeSmoking: false,
  includePetFriendly: false,
  profile: {
    nameTitle: 0,
    firstName: '',
    lastName: '',
    birthday: null,
    country: '',
    preferLang: 'en',
    phoneCountry: null,
    email: '',
  },
  promoCode: '',
  promotion: null,
  applyingForBin: false,
  inputFields: [],
  errors: [],
  errorMessage: '',
  isSubmittingBooking: false,
  bookingDetail: null,
  isEditing: false,
  bookingId: null,
  bookingNumber: '',
  email: '',
  oldCheckIn: null,
  oldCheckOut: null,
  oldRoomTypes: '',
  oldAdults: 0,
  oldChildren: 0,
  oldBooking: null,
};

export const BookingShape = PropTypes.shape({
  hotel: Hotel.shape(),
  checkIn: PropTypes.instanceOf(moment),
  checkOut: PropTypes.instanceOf(moment),
  flashCode: PropTypes.string,
  roomTypes: PropTypes.arrayOf(
    PropTypes.shape({
      room: Room.shape(),
      numberOfRooms: PropTypes.number,
      adults: PropTypes.number,
      children: PropTypes.number,
      limitGuest: PropTypes.number,
      price: PropTypes.shape({
        normal: PropTypes.number,
        adultNormal: PropTypes.number,
        childNormal: PropTypes.number,
        adultNormalNoTax: PropTypes.number,
        childNormalNoTax: PropTypes.number,
        normalNoTax: PropTypes.number,
        member: PropTypes.number,
        memberNoTax: PropTypes.number,
        adultMember: PropTypes.number,
        childMember: PropTypes.number,
        adultMemberNoTax: PropTypes.number,
        childMemberNoTax: PropTypes.number,
        normalHotel: PropTypes.number,
        normalHotelNoTax: PropTypes.number,
        adultNormalHotel: PropTypes.number,
        childNormalHotel: PropTypes.number,
        adultNormalHotelNoTax: PropTypes.number,
        childNormalHotelNoTax: PropTypes.number,
        memberHotel: PropTypes.number,
        memberHotelNoTax: PropTypes.number,
        adultMemberHotel: PropTypes.number,
        childMemberHotel: PropTypes.number,
        adultMemberHotelNoTax: PropTypes.number,
        childMemberHotelNoTax: PropTypes.number,
      }),
    }),
  ),
  payment: PropTypes.oneOf([
    PAYMENT_CREDIT_CARD,
    PAYMENT_PAY_AT_HOTEL,
    PAYMENT_WALLET,
    // PAYMENT_MAYA_ONLINE,
    PAYMENT_MAYA_WALLET,
    PAYMENT_MAYA_QRPH,
  ]),
  supportedPayment: PropTypes.arrayOf(
    PropTypes.oneOf([
      PAYMENT_CREDIT_CARD,
      PAYMENT_PAY_AT_HOTEL,
      PAYMENT_WALLET,
      // PAYMENT_MAYA_ONLINE,
      PAYMENT_MAYA_WALLET,
      PAYMENT_MAYA_QRPH,
    ]),
  ),
  creditCard: PropTypes.shape({
    cardHolder: PropTypes.string,
    cardNumber: PropTypes.string,
    expiry: PropTypes.string,
    cvc: PropTypes.string,
  }),
  wallet: PropTypes.shape({
    source: PropTypes.string,
    phone: PropTypes.string,
  }),
  numberOfRooms: PropTypes.number,
  adults: PropTypes.number,
  children: PropTypes.number,
  joinRemembered: PropTypes.bool,
  includeBreakfast: PropTypes.bool,
  includeSmoking: PropTypes.bool,
  includePetFriendly: PropTypes.bool,
  profile: PropTypes.shape({
    nameTitle: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    birthday: PropTypes.instanceOf(moment),
    country: PropTypes.string,
    preferLang: PropTypes.string,
    phoneCountry: PropTypes.object,
    email: PropTypes.string,
  }),
  sundrySales: PropTypes.arrayOf(
    PropTypes.shape({
      item: SundryItemType.shape(),
      numberOfItem: PropTypes.number,
    }),
  ),
  promoCode: PropTypes.string,
  promotion: Promotion.shape(),
  applyingForBin: PropTypes.bool,
  inputFields: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.arrayOf(PropTypes.string),
  isSubmittingBooking: PropTypes.bool,
  bookingDetail: BookingType.shape(),
});

export const MaxNumberOfRooms = 5;

function getMaxNumberOfSupportedAdultsInRoom(room) {
  const adults = [];
  room.guest_combination.forEach(combination => {
    adults.push(combination.adult);
  });
  return Math.max(...adults);
}

function getMaxNumberOfSupportedChildrenInRoom(room, adults) {
  let children = [0];
  room.guest_combination.some(combination => {
    if (combination.adult === adults) {
      children = combination.child;
      return true;
    }
    return false;
  });
  return Math.max(...children);
}

export function roomCanFitRequirement(room, adultsPerRoom, childrenPerRoom) {
  let canFitRequirement = false;
  room.guest_combination.some(combination => {
    if (combination.adult >= adultsPerRoom) {
      if (childrenPerRoom > 0) {
        combination.child.some(childCombination => {
          if (childCombination >= childrenPerRoom) {
            canFitRequirement = true;
            return true;
          }
          return false;
        });
      } else {
        canFitRequirement = true;
        return true;
      }
    }
    return false;
  });

  return canFitRequirement;
}

export function clearBooking() {
  return async dispatch => {
    const booking = { ...initialBooking };
    dispatch({
      type: CREATE_BOOKING,
      payload: booking,
    });
    return booking;
  };
}

export function createBooking(
  hotelId,
  checkIn,
  checkOut,
  numberOfRooms,
  adults,
  children,
  includeBreakfast,
  includeSmoking,
  includePetFriendly,
  flashCode,
) {
  return async (dispatch, getState, { history, client }) => {
    let birthday = '';
    if (getState().user.isLoggedIn) {
      const { user } = getState().user;
      birthday = user.birthday;
    }
    let code = '';
    if (flashCode) {
      code = flashCode;
    }
    const Hotel = gql`query Hotel{
      hotel(hotelId: ${hotelId} checkIn: "${checkIn.format(
      'YYYY-MM-DD',
    )}" checkOut: "${checkOut.format(
      'YYYY-MM-DD',
    )}" birthday: "${birthday}" code: "${code}"){
    id
    seekda_id
    name
    name_en
    slug_name
    currency
    code
    address
    phone
    email
    country_code
    coordinate
    is_birthday_remembered
    start_price_remembered
    images
    payment_method
    payment_method_maya
    location {
      parent
    }
    breakfast{
      name
      price_per_guest
      price_per_guest_no_tax
    }
    smoking{
      hotel_id
      price_per_night
    }
    pet_friendly{
      hotel_id
      price_per_night
    }
    flash_sale {
      payment_methods
    }
    rooms{
      id
      name
      code
      description
      images
      available
      limit_capacity
      features {
        name
      }
      guest_combination {
        adult
        child
      }
      rate {
        id
        code
        special_rate_description
        available_rates {
          limit_guest
          selling_rate_non_member {
            price
            additional_amount_adult
            additional_amount_child
            price_no_tax
            additional_amount_adult_no_tax
            additional_amount_child_no_tax
          }
          selling_rate_member {
            price
            additional_amount_adult
            additional_amount_child
            price_no_tax
            additional_amount_adult_no_tax
            additional_amount_child_no_tax
          }
          selling_rate_non_member_pay_at_hotel{
            price
            additional_amount_adult
            additional_amount_child
            price_no_tax
            additional_amount_adult_no_tax
            additional_amount_child_no_tax
          }
          selling_rate_member_pay_at_hotel{
            price
            additional_amount_adult
            additional_amount_child
            price_no_tax
            additional_amount_adult_no_tax
            additional_amount_child_no_tax
          }
        }
      }
    }
    policy_guarantee{
      pay_now_rules
      pay_later_rules
      expire_time
    }
    }
    }`;
    const { data } = await client.networkInterface.query({
      query: Hotel,
    });
    const { hotel } = data;
    const SundrySale = gql`query SundrySale{
      getSundrySale(hotelId: ${hotelId}){
        id
        title
        description
        price
        max_item_per_booking
        image_thumb
        image_full
        start
        end
      }
    }`;
    const {
      data: { getSundrySale },
    } = await client.networkInterface.query({
      query: SundrySale,
    });
    const sundrySales = [];
    getSundrySale.forEach(item => {
      let numberOfItem = 0;
      if (
        getState().booking &&
        getState().booking.hotel &&
        hotel.id === getState().booking.hotel.id
      ) {
        getState().booking.sundrySales.forEach(i => {
          if (i.item && i.item.id === item.id) {
            numberOfItem = i.numberOfItem;
          }
        });
      }
      sundrySales.push({
        item,
        numberOfItem,
      });
    });
    let booking = { ...initialBooking };
    const { user } = getState();
    const roomTypes =
      (getState().booking &&
        getState().booking.hotel &&
        hotel.id === getState().booking.hotel.id &&
        hotel.is_birthday_remembered ===
          getState().booking.hotel.is_birthday_remembered &&
        checkIn.isSame(getState().booking.checkIn) &&
        checkOut.isSame(getState().booking.checkOut) &&
        !getState().booking.bookingDetail &&
        getState().booking.numberOfRooms === numberOfRooms &&
        getState().booking.adults === adults &&
        getState().booking.children === children &&
        getState().booking.roomTypes) ||
      [];
    booking = {
      ...booking,
      sundrySales,
      hotel,
      checkIn,
      checkOut,
      flashCode,
      numberOfRooms,
      adults /* - unAssignedNumberOfAdults */,
      children /* - unAssignedNumberOfChildren */,
      joinRemembered: booking.joinRemembered || user.isLoggedIn,
      payment: PAYMENT_CREDIT_CARD,
      includeBreakfast,
      includeSmoking,
      includePetFriendly,
      creditCard: {
        cardHolder: '',
        cardNumber: '',
        expiry: '',
        cvc: '',
      },
      wallet: {
        source: '',
        phone: '',
      },
    };
    if (hotel.payment_method) {
      booking.supportedPayment = hotel.payment_method;
    }
    if (hotel.payment_method_maya) {
      if (hotel.payment_method_maya.indexOf(PAYMENT_MAYA_WALLET) > -1) {
        booking.supportedPayment.push(PAYMENT_MAYA_WALLET);
      }
    }
    if (hotel.flash_sale && hotel.flash_sale.payment_methods) {
      booking.supportedPayment = [];
      if (hotel.flash_sale.payment_methods.indexOf(PAYMENT_CREDIT_CARD) > -1) {
        booking.supportedPayment.push(PAYMENT_CREDIT_CARD);
      }
      if (hotel.flash_sale.payment_methods.indexOf(PAYMENT_WALLET) > -1) {
        booking.supportedPayment.push(PAYMENT_WALLET);
      }
      if (hotel.flash_sale.payment_methods.indexOf(PAYMENT_PAY_AT_HOTEL) > -1) {
        booking.supportedPayment.push(PAYMENT_PAY_AT_HOTEL);
      }
      // if (hotel.flash_sale.payment_methods.indexOf(PAYMENT_MAYA_ONLINE) > -1) {
      //   booking.supportedPayment.push(PAYMENT_MAYA_ONLINE);
      // }
      if (hotel.flash_sale.payment_methods.indexOf(PAYMENT_MAYA_QRPH) > -1) {
        booking.supportedPayment.push(PAYMENT_MAYA_QRPH);
      }
    }
    if (hotel.rooms.length && roomTypes.length === 0) {
      hotel.rooms.forEach(room => {
        const price = {
          normal: 0,
          normalNoTax: 0,
          adultNormal: 0,
          childNormal: 0,
          adultNormalNoTax: 0,
          childNormalNoTax: 0,

          member: 0,
          adultMember: 0,
          childMember: 0,
          memberNoTax: 0,
          adultMemberNoTax: 0,
          childMemberNoTax: 0,

          normalHotel: 0,
          adultNormalHotel: 0,
          childNormalHotel: 0,
          normalHotelNoTax: 0,
          adultNormalHotelNoTax: 0,
          childNormalHotelNoTax: 0,

          memberHotel: 0,
          adultMemberHotel: 0,
          childMemberHotel: 0,
          memberHotelNoTax: 0,
          adultMemberHotelNoTax: 0,
          childMemberHotelNoTax: 0,
        };
        room.rate.available_rates.forEach(rate => {
          price.normal += rate.selling_rate_non_member.price;
          price.adultNormal +=
            rate.selling_rate_non_member.additional_amount_adult;
          price.childNormal +=
            rate.selling_rate_non_member.additional_amount_child;
          price.normalNoTax += rate.selling_rate_non_member.price_no_tax;
          price.adultNormalNoTax +=
            rate.selling_rate_non_member.additional_amount_adult_no_tax;
          price.childNormalNoTax +=
            rate.selling_rate_non_member.additional_amount_child_no_tax;

          price.member += rate.selling_rate_member.price;
          price.adultMember += rate.selling_rate_member.additional_amount_adult;
          price.childMember += rate.selling_rate_member.additional_amount_child;
          price.memberNoTax += rate.selling_rate_member.price_no_tax;
          price.adultMemberNoTax +=
            rate.selling_rate_member.additional_amount_adult_no_tax;
          price.childMemberNoTax +=
            rate.selling_rate_member.additional_amount_child_no_tax;

          price.normalHotel += rate.selling_rate_non_member_pay_at_hotel.price;
          price.adultNormalHotel +=
            rate.selling_rate_non_member_pay_at_hotel.additional_amount_adult;
          price.childNormalHotel +=
            rate.selling_rate_non_member_pay_at_hotel.additional_amount_child;
          price.normalHotelNoTax +=
            rate.selling_rate_non_member_pay_at_hotel.price_no_tax;
          price.adultNormalHotelNoTax +=
            rate.selling_rate_non_member_pay_at_hotel.additional_amount_adult_no_tax;
          price.childNormalHotelNoTax +=
            rate.selling_rate_non_member_pay_at_hotel.additional_amount_child_no_tax;

          price.memberHotel += rate.selling_rate_member_pay_at_hotel.price;
          price.adultMemberHotel +=
            rate.selling_rate_member_pay_at_hotel.additional_amount_adult;
          price.childMemberHotel +=
            rate.selling_rate_member_pay_at_hotel.additional_amount_child;
          price.memberHotelNoTax +=
            rate.selling_rate_member_pay_at_hotel.price_no_tax;
          price.adultMemberHotelNoTax +=
            rate.selling_rate_member_pay_at_hotel.additional_amount_adult_no_tax;
          price.childMemberHotelNoTax +=
            rate.selling_rate_member_pay_at_hotel.additional_amount_child_no_tax;
        });
        roomTypes.push({
          room: JSON.parse(JSON.stringify(room)),
          limitGuest: room.rate.available_rates[0].limit_guest,
          numberOfRooms: 0,
          adults: 0,
          children: 0,
          price,
        });
      });
    }
    if (numberOfRooms > MaxNumberOfRooms) {
      numberOfRooms = MaxNumberOfRooms;
    }
    if (adults < numberOfRooms) {
      adults = numberOfRooms;
    }
    let profile = {
      nameTitle: 0,
      firstName: '',
      lastName: '',
      birthday: null,
      country: '',
      preferLang: 'en',
      phoneCountry: null,
      email: '',
    };
    if (booking.profile && booking.profile.firstName) {
      profile = {
        ...booking.profile,
        birthday: booking.profile.birthday
          ? moment(booking.profile.birthday)
          : '',
      };
    } else if (user.isLoggedIn) {
      const {
        name_title_id, // eslint-disable-line
        first_name, // eslint-disable-line
        last_name, // eslint-disable-line
        birthday,
        country_of_residence_code, // eslint-disable-line
        prefer_lang,
        phoneCountry,
        email,
      } = user.user;
      profile = {
        ...profile,
        nameTitle: name_title_id,
        firstName: first_name,
        lastName: last_name,
        birthday: moment(birthday),
        country: country_of_residence_code,
        preferLang: prefer_lang,
        phoneCountry,
        email,
      };
    } else if (process.env.BROWSER) {
      const { hostname } = window.location;
      const paths = hostname.split('.');
      const domain = paths.slice(paths.length - 2, paths.length).join('.');
      const cookies = new Cookies();
      const cookieProfile = cookies.get('rphl.profile', {
        expires: moment()
          .add(1, 'year')
          .toDate(),
        path: '/',
        domain,
      });
      if (cookieProfile) {
        profile = {
          ...cookieProfile,
          birthday: moment(cookieProfile.birthday),
        };
      }
    }
    booking = {
      ...booking,
      roomTypes,
      profile,
    };
    if (process.env.BROWSER) {
      const query = queryString.parse(history.location.search);

      const changedRequirements =
        parseInt(query.rooms, 10) !== booking.numberOfRooms ||
        parseInt(query.adults, 10) !== booking.adults ||
        parseInt(query.children, 10) !== booking.children;

      // Because we automatically reduce rooms, adults, and children to fit our room requirement
      // So we need to update the query string on the browser
      // And to prevent infinite create booking loops, we only change the query string only when it is really different
      if (changedRequirements) {
        let newurl = `${`${window.location.protocol}//${window.location.host}${window.location.pathname}`}?hotel=${
          booking.hotel.id
        }&check_in=${booking.checkIn.format(
          'YYYY-MM-DD',
        )}&check_out=${booking.checkOut.format('YYYY-MM-DD')}&rooms=${
          booking.numberOfRooms
        }&adults=${booking.adults}&children=${booking.children}`;
        if (includeBreakfast) {
          newurl += '&breakfast=1';
        }
        if (includeSmoking) {
          newurl += '&smoking=1';
        }
        if (includePetFriendly) {
          newurl += '&pet_friendly=1';
        }
        if (flashCode) {
          newurl += `&code=${flashCode}`;
        }
        window.history.replaceState({ path: newurl }, '', newurl);
      }
    }
    dispatch({
      type: CREATE_BOOKING,
      payload: booking,
    });
    return booking;
  };
}

export function updateBookingPrice(hotel) {
  return async (dispatch, getState) => {
    if (!getState().booking) {
      return;
    }
    const { booking } = getState();
    hotel.rooms.forEach(room => {
      const price = {
        normal: 0,
        normalNoTax: 0,
        adultNormal: 0,
        childNormal: 0,
        adultNormalNoTax: 0,
        childNormalNoTax: 0,

        member: 0,
        adultMember: 0,
        childMember: 0,
        memberNoTax: 0,
        adultMemberNoTax: 0,
        childMemberNoTax: 0,

        normalHotel: 0,
        adultNormalHotel: 0,
        childNormalHotel: 0,
        normalHotelNoTax: 0,
        adultNormalHotelNoTax: 0,
        childNormalHotelNoTax: 0,

        memberHotel: 0,
        adultMemberHotel: 0,
        childMemberHotel: 0,
        memberHotelNoTax: 0,
        adultMemberHotelNoTax: 0,
        childMemberHotelNoTax: 0,
      };
      room.rate.available_rates.forEach(rate => {
        price.normal += rate.selling_rate_non_member.price;
        price.adultNormal +=
          rate.selling_rate_non_member.additional_amount_adult;
        price.childNormal +=
          rate.selling_rate_non_member.additional_amount_child;
        price.normalNoTax += rate.selling_rate_non_member.price_no_tax;
        price.adultNormalNoTax +=
          rate.selling_rate_non_member.additional_amount_adult_no_tax;
        price.childNormalNoTax +=
          rate.selling_rate_non_member.additional_amount_child_no_tax;

        price.member += rate.selling_rate_member.price;
        price.adultMember += rate.selling_rate_member.additional_amount_adult;
        price.childMember += rate.selling_rate_member.additional_amount_child;
        price.memberNoTax += rate.selling_rate_member.price_no_tax;
        price.adultMemberNoTax +=
          rate.selling_rate_member.additional_amount_adult_no_tax;
        price.childMemberNoTax +=
          rate.selling_rate_member.additional_amount_child_no_tax;

        price.normalHotel += rate.selling_rate_non_member_pay_at_hotel.price;
        price.adultNormalHotel +=
          rate.selling_rate_non_member_pay_at_hotel.additional_amount_adult;
        price.childNormalHotel +=
          rate.selling_rate_non_member_pay_at_hotel.additional_amount_child;
        price.normalHotelNoTax +=
          rate.selling_rate_non_member_pay_at_hotel.price_no_tax;
        price.adultNormalHotelNoTax +=
          rate.selling_rate_non_member_pay_at_hotel.additional_amount_adult_no_tax;
        price.childNormalHotelNoTax +=
          rate.selling_rate_non_member_pay_at_hotel.additional_amount_child_no_tax;

        price.memberHotel += rate.selling_rate_member_pay_at_hotel.price;
        price.adultMemberHotel +=
          rate.selling_rate_member_pay_at_hotel.additional_amount_adult;
        price.childMemberHotel +=
          rate.selling_rate_member_pay_at_hotel.additional_amount_child;
        price.memberHotelNoTax +=
          rate.selling_rate_member_pay_at_hotel.price_no_tax;
        price.adultMemberHotelNoTax +=
          rate.selling_rate_member_pay_at_hotel.additional_amount_adult_no_tax;
        price.childMemberHotelNoTax +=
          rate.selling_rate_member_pay_at_hotel.additional_amount_child_no_tax;
      });
      booking.hotel = hotel;
      booking.roomTypes.forEach(roomType => {
        if (roomType.room.id === room.id) {
          roomType.room = JSON.parse(JSON.stringify(room));
          roomType.limitGuest = room.rate.available_rates[0].limit_guest;
          roomType.price = price;
        }
      });
    });
    dispatch({
      type: CREATE_BOOKING,
      payload: {
        ...booking,
        errors: [],
        errorMessage: '',
      },
    });
  };
}

export function changeRoom(roomId, numberOfRooms) {
  return async (dispatch, getState, { history }) => {
    const state = getState();
    let { booking } = state;
    let foundRoomType = null;
    let oldNumberOfRooms = 0;
    let selectedRoom = false;
    booking.roomTypes.forEach(roomType => {
      if (roomType.room.id === roomId) {
        foundRoomType = roomType;
        oldNumberOfRooms = foundRoomType.numberOfRooms;
      }
      if (!selectedRoom) {
        selectedRoom = roomType.numberOfRooms > 0;
      }
    });
    if (
      numberOfRooms <= Math.min(MaxNumberOfRooms, foundRoomType.room.available)
    ) {
      if (foundRoomType) {
        await dispatch({
          type: CHANGE_ROOM,
          payload: {
            roomType: {
              room: foundRoomType.room,
              numberOfRooms,
            },
          },
        });

        booking = getState().booking;
        let { adults, children } = booking;
        const { roomTypes } = booking;
        let selectedNumberOfRooms = 0;
        roomTypes.forEach(roomType => {
          selectedNumberOfRooms += roomType.numberOfRooms;
        });
        if (adults < selectedNumberOfRooms && selectedNumberOfRooms > 0) {
          adults = selectedNumberOfRooms;
        }
        roomTypes.forEach(roomType => {
          roomType.adults = 0;
          roomType.children = 0;
        });
        let unAssignedNumberOfRooms = selectedNumberOfRooms;
        let unAssignedNumberOfAdults = adults;
        let unAssignedNumberOfChildren = children;

        let maxSupportNumberOfAdults = 0;
        roomTypes.forEach(roomType => {
          const ma = getMaxNumberOfSupportedAdultsInRoom(roomType.room);
          if (ma > maxSupportNumberOfAdults) {
            maxSupportNumberOfAdults = ma;
          }
          if (roomType.numberOfRooms > 0) {
            for (let i = roomType.numberOfRooms; i > 0; i -= 1) {
              const adultsPerRoom = Math.floor(
                unAssignedNumberOfAdults / unAssignedNumberOfRooms,
              );
              const childrenPerRoom = Math.floor(
                unAssignedNumberOfChildren / unAssignedNumberOfRooms,
              );
              if (
                roomCanFitRequirement(
                  roomType.room,
                  adultsPerRoom,
                  childrenPerRoom,
                )
              ) {
                roomType.adults += adultsPerRoom;
                roomType.children += childrenPerRoom;

                unAssignedNumberOfRooms -= 1;
                unAssignedNumberOfAdults -= adultsPerRoom;
                unAssignedNumberOfChildren -= childrenPerRoom;
              } else {
                const supportedAdult = getMaxNumberOfSupportedAdultsInRoom(
                  roomType.room,
                );
                const supportedChild = getMaxNumberOfSupportedChildrenInRoom(
                  roomType.room,
                  supportedAdult,
                );
                roomType.adults += Math.min(adultsPerRoom, supportedAdult);
                roomType.children += Math.min(childrenPerRoom, supportedChild);

                unAssignedNumberOfRooms -= 1;
                unAssignedNumberOfAdults -= supportedAdult;
                unAssignedNumberOfChildren -= supportedChild;
                if (unAssignedNumberOfAdults < 0) {
                  unAssignedNumberOfAdults = 0;
                }
                if (unAssignedNumberOfChildren < 0) {
                  unAssignedNumberOfChildren = 0;
                }
              }
            }
          }
        });
        if (oldNumberOfRooms < numberOfRooms || !selectedRoom) {
          if (unAssignedNumberOfAdults > 0) {
            unAssignedNumberOfAdults = 0;
          }

          if (unAssignedNumberOfChildren > 0) {
            unAssignedNumberOfChildren = 0;
          }
        }
        let specificAdult = 0;
        let specificChildren = 0;
        if (process.env.BROWSER) {
          const query = queryString.parse(history.location.search);
          if (query.adults) {
            specificAdult = parseInt(query.adults, 10);
            specificChildren = parseInt(query.children, 10);
          }
        }
        if (!specificAdult && booking.oldAdults) {
          specificAdult = booking.oldAdults;
        }
        if (!specificChildren && booking.oldChildren) {
          specificChildren = booking.oldChildren;
        }
        adults -= unAssignedNumberOfAdults;
        children -= unAssignedNumberOfChildren;
        await dispatch({
          type: AUTO_ASSIGN_GUESTS,
          payload: {
            roomTypes,
            numberOfRooms: Math.max(
              Math.round(
                (adults - unAssignedNumberOfAdults) / maxSupportNumberOfAdults,
              ),
              selectedNumberOfRooms,
            ),
            adults: specificAdult ? Math.max(adults, specificAdult) : adults,
            children: specificChildren
              ? Math.max(children, specificChildren)
              : children,
          },
        });

        if (process.env.BROWSER) {
          const query = queryString.parse(history.location.search);

          const changedRequirements =
            parseInt(query.rooms, 10) !== selectedNumberOfRooms ||
            parseInt(query.adults, 10) !== booking.adults ||
            parseInt(query.children, 10) !== booking.children;

          // Because we automatically reduce rooms, adults, and children to fit our room requirement
          // So we need to update the query string on the browser
          // And to prevent infinite create booking loops, we only change the query string only when it is really different
          if (changedRequirements) {
            let newurl = `${`${window.location.protocol}//${window.location.host}${window.location.pathname}`}?hotel=${
              booking.hotel.id
            }&check_in=${booking.checkIn.format(
              'YYYY-MM-DD',
            )}&check_out=${booking.checkOut.format(
              'YYYY-MM-DD',
            )}&rooms=${Math.max(
              Math.round(adults / 2),
              selectedNumberOfRooms,
            )}&adults=${booking.adults}&children=${booking.children}`;
            if (booking.includeBreakfast) {
              newurl += '&breakfast=1';
            }
            if (booking.includeSmoking) {
              newurl += '&smoking=1';
            }
            if (booking.includePetFriendly) {
              newurl += '&pet_friendly=1';
            }
            if (booking.flashCode) {
              newurl += `&code=${booking.flashCode}`;
            }
            if (booking.bookingId) {
              newurl += `&booking_id=${booking.bookingId}`;
            }
            if (booking.bookingNumber) {
              newurl += `&confirmation=${booking.bookingNumber}`;
            }
            if (booking.email) {
              newurl += `&email=${encodeURIComponent(booking.email)}`;
            }
            window.history.replaceState({ path: newurl }, '', newurl);
          }
        }

        return booking;
      }
    }
    return false;
  };
}

export function joinRemembered(join) {
  return async dispatch => {
    dispatch({
      type: JOIN_REMEMBERED,
      payload: join,
    });
  };
}

export function includeBreakfast(include) {
  return async (dispatch, getState) => {
    const state = getState();
    const { booking } = state;

    dispatch({
      type: INCLUDE_BREAKFAST,
      payload: include,
    });

    if (process.env.BROWSER) {
      let newurl = `${`${window.location.protocol}//${window.location.host}${window.location.pathname}`}?hotel=${
        booking.hotel.id
      }&check_in=${booking.checkIn.format(
        'YYYY-MM-DD',
      )}&check_out=${booking.checkOut.format('YYYY-MM-DD')}&rooms=${
        booking.numberOfRooms
      }&adults=${booking.adults}&children=${booking.children}`;
      if (include) {
        newurl += '&breakfast=1';
      }
      if (booking.includeSmoking) {
        newurl += '&smoking=1';
      }
      if (booking.includePetFriendly) {
        newurl += '&pet_friendly=1';
      }
      if (booking.flashCode) {
        newurl += `&code=${booking.flashCode}`;
      }
      if (booking.bookingId) {
        newurl += `&booking_id=${booking.bookingId}`;
      }
      window.history.replaceState({ path: newurl }, '', newurl);
    }

    return booking;
  };
}

export function includeSmoking(include) {
  return async (dispatch, getState) => {
    const state = getState();
    const { booking } = state;

    dispatch({
      type: INCLUDE_SMOKING,
      payload: include,
    });

    if (process.env.BROWSER) {
      let newurl = `${`${window.location.protocol}//${window.location.host}${window.location.pathname}`}?hotel=${
        booking.hotel.id
      }&check_in=${booking.checkIn.format(
        'YYYY-MM-DD',
      )}&check_out=${booking.checkOut.format('YYYY-MM-DD')}&rooms=${
        booking.numberOfRooms
      }&adults=${booking.adults}&children=${booking.children}`;
      if (booking.includeBreakfast) {
        newurl += '&breakfast=1';
      }
      if (booking.includePetFriendly) {
        newurl += '&pet_friendly=1';
      }
      if (include) {
        newurl += '&smoking=1';
      }
      if (booking.flashCode) {
        newurl += `&code=${booking.flashCode}`;
      }
      if (booking.bookingId) {
        newurl += `&booking_id=${booking.bookingId}`;
      }
      window.history.replaceState({ path: newurl }, '', newurl);
    }

    return booking;
  };
}

export function includePetFriendly(include) {
  return async (dispatch, getState) => {
    const state = getState();
    const { booking } = state;

    dispatch({
      type: INCLUDE_PET_FRIENDLY,
      payload: include,
    });

    if (process.env.BROWSER) {
      let newurl = `${`${window.location.protocol}//${window.location.host}${window.location.pathname}`}?hotel=${
        booking.hotel.id
      }&check_in=${booking.checkIn.format(
        'YYYY-MM-DD',
      )}&check_out=${booking.checkOut.format('YYYY-MM-DD')}&rooms=${
        booking.numberOfRooms
      }&adults=${booking.adults}&children=${booking.children}`;
      if (booking.includeBreakfast) {
        newurl += '&breakfast=1';
      }
      if (booking.includeSmoking) {
        newurl += '&smoking=1';
      }
      if (include) {
        newurl += '&pet_friendly=1';
      }
      if (booking.flashCode) {
        newurl += `&code=${booking.flashCode}`;
      }
      if (booking.bookingId) {
        newurl += `&booking_id=${booking.bookingId}`;
      }
      window.history.replaceState({ path: newurl }, '', newurl);
    }

    return booking;
  };
}

export function addSundryItem(item) {
  return async (dispatch, getState) => {
    const state = getState();
    const { booking } = state;
    booking.sundrySales.forEach(s => {
      if (s.item.id === item.item.id) {
        s.numberOfItem = item.numberOfItem;
      }
    });
    dispatch({
      type: ADD_SUNDRY_ITEM,
      payload: booking.sundrySales,
    });
  };
}

export function addGuestProfile(profile, stage = true) {
  return async (dispatch, getState, { client }) => {
    const { booking } = getState();
    const { user } = getState();

    const errors = [];
    let inputFields = [];
    if (stage) {
      inputFields = ['firstName', 'lastName', 'birthday', 'email', 'phone'];
    } else {
      const fields = isArray(booking.inputFields) ? booking.inputFields : [];
      inputFields = addFieldArray(fields, profile.inputField);
    }

    if (inArray(inputFields, 'firstName') || stage) {
      if (
        profile.firstName === '' ||
        !(
          isStringAlpha(profile.firstName) ||
          isNonLatinCharacters(profile.firstName)
        )
      ) {
        errors.push('firstName');
      }
    }

    if (inArray(inputFields, 'lastName') || stage) {
      if (
        !profile.lastName === '' ||
        !(
          isStringAlpha(profile.lastName) ||
          isNonLatinCharacters(profile.lastName)
        )
      ) {
        errors.push('lastName');
      }
    }

    if (booking.joinRemembered) {
      if (inArray(inputFields, 'birthday') || stage) {
        if (profile.birthday === null) {
          errors.push('birthday');
        }
      }
      // if (profile.document === '') {
      //   errors.push('document');
      // }
    }

    if (inArray(inputFields, 'phone') || stage) {
      /* eslint-disable no-undef */
      if (typeof $ !== 'undefined') {
        if (!$('#phone').intlTelInput('isValidNumber') && $('#phone').val() ) {
          errors.push('phone');
        }
      }
      /* eslint-enable no-undef */
    }

    if (inArray(inputFields, 'email') || stage) {
      if (profile.email === '' || !isEmail(profile.email)) {
        errors.push('email');
      }
    }
    booking.inputFields = inputFields;
    booking.errors = errors;

    let errorSubmitBooking = false;
    let errorMessage = '';
    if (booking.errorSubmitBooking) {
      errorMessage = booking.errorMessage;
      if (booking.errors.indexOf('email') > -1) {
        errorSubmitBooking = profile.email === booking.oldEmail;
      }
      if (booking.errors.indexOf('birthday') > -1 && profile.birthday) {
        errorSubmitBooking = profile.birthday.isSame(booking.oldBirthday);
      }
      if (!errorSubmitBooking) {
        errorMessage = '';
      }
    }

    if (errors.length > 0) {
      dispatch({
        type: FAIL_GUEST_PROFILE,
        payload: {
          profile,
          errors,
          errorSubmitBooking,
        },
      });
    } else {
      dispatch({
        type: SUCCESS_GUEST_PROFILE,
        payload: {
          profile,
          errorSubmitBooking,
          errorMessage,
          user,
        },
      });
      const { hostname } = window.location;
      const paths = hostname.split('.');
      const domain = paths.slice(paths.length - 2, paths.length).join('.');
      const cookies = new Cookies();
      cookies.set('rphl.profile', JSON.stringify(profile), {
        expires: moment()
          .add(1, 'year')
          .toDate(),
        path: '/',
        domain,
      });
      // Inspectlet.identify(profile.email, '');
      // mp.setEmail(profile.email);
      let bMonth = 999;
      if (profile.birthday) {
        bMonth = profile.birthday.month();
      }
      const bookingOnBirthday = () =>
        (!booking.hotel.is_birthday_remembered &&
          (bMonth === booking.checkIn.month() ||
            bMonth === booking.checkOut.month())) ||
        (booking.hotel.is_birthday_remembered &&
          bMonth !== booking.checkIn.month() &&
          bMonth !== booking.checkOut.month());
      if (
        booking.checkIn &&
        booking.checkOut &&
        profile.birthday &&
        bookingOnBirthday()
      ) {
        const hotelId = booking.hotel.id;
        const checkIn = booking.checkIn.format('YYYY-MM-DD');
        const checkOut = booking.checkOut.format('YYYY-MM-DD');
        const birthday = profile.birthday.isValid()
          ? profile.birthday.format('YYYY-MM-DD')
          : '';
        const HotelQuery = gql`query Hotel{
        hotel(hotelId: ${hotelId} checkIn: "${checkIn}" checkOut: "${checkOut}" birthday: "${birthday}"){
        id
        seekda_id
        name
        name_en
        slug_name
        currency
        code
        address
        phone
        email
        is_birthday_remembered
        images
        country_code
        payment_method
        payment_method_maya
        coordinate
        location {
          parent
        }
        breakfast{
          name
          price_per_guest
          price_per_guest_no_tax
        }
        pet_friendly{
          price_per_night
        }
        policy_guarantee{
          pay_now_rules
          pay_later_rules
          expire_time
        }
        flash_sale {
          payment_methods
        }
        rooms{
          id
          name
          code
          description
          images
          available
          limit_capacity
          guest_combination{
            adult
            child
          }
          rate {
            id
            code
            available_rates {
              limit_guest
              selling_rate_non_member {
                price
                additional_amount_adult
                additional_amount_child
                price_no_tax
                additional_amount_adult_no_tax
                additional_amount_child_no_tax
              }
              selling_rate_member {
                price
                additional_amount_adult
                additional_amount_child
                price_no_tax
                additional_amount_adult_no_tax
                additional_amount_child_no_tax
              }
              selling_rate_non_member_pay_at_hotel{
                price
                additional_amount_adult
                additional_amount_child
                price_no_tax
                additional_amount_adult_no_tax
                additional_amount_child_no_tax
              }
              selling_rate_member_pay_at_hotel{
                price
                additional_amount_adult
                additional_amount_child
                price_no_tax
                additional_amount_adult_no_tax
                additional_amount_child_no_tax
              }
            }
          }
        }
        }
        }`;
        const { data } = await client.networkInterface.query({
          query: HotelQuery,
        });
        dispatch(updateBookingPrice(data.hotel));
      }
    }
    return booking;
  };
}

export function changePaymentMethod(method) {
  return async (dispatch, getState) => {
    const { booking } = getState();
    if (booking.payment !== method || booking.mayaPayment !== method) {
      if (method === PAYMENT_WALLET && booking.wallet.source === '') {
        booking.wallet.source = 'true_wallet';
      }
      if (method === PAYMENT_MAYA_WALLET || method === PAYMENT_MAYA_QRPH) {
        dispatch({
          type: CHANGE_PAYMENT_METHOD,
          payload: 'credit_card',
        });
        dispatch({
          type: CHANGE_MAYA_PAYMENT_METHOD,
          payload: method,
        });
      }
      if (
        method === PAYMENT_CREDIT_CARD ||
        method === PAYMENT_PAY_AT_HOTEL ||
        method === PAYMENT_WALLET
      ) {
        dispatch({
          type: CHANGE_PAYMENT_METHOD,
          payload: method,
        });
        dispatch({
          type: CHANGE_MAYA_PAYMENT_METHOD,
          payload: '',
        });
      }
    }
    return booking;
  };
}

const supportedCards = [
  'visa',
  'master-card',
  'american-express',
  'jcb',
  'unionpay',
];

function isCardNumberValid(cardNumber) {
  let ok = false;
  const valid = cardValidator.number(cardNumber);
  if (valid !== null && valid.card !== null && valid.isValid) {
    const { type } = valid.card;
    ok = supportedCards.indexOf(type) > -1;
  }
  return ok;
}

function isExpiryValid(expiry) {
  const valid = cardValidator.expirationDate(expiry);
  return valid !== null && valid.isValid;
}

function isCVCValid({ cardNumber, cvc }) {
  let ok = false;
  const number = cardValidator.number(cardNumber);
  if (number !== null && number.card !== null && number.isPotentiallyValid) {
    const cvv = cardValidator.cvv(cvc, number.card.code.size);
    ok = cvv !== null && cvv.isValid;
  }
  return ok;
}

function creditCardValidate(creditCard) {
  const errors = [];
  if (creditCard.cardHolder === '' || !isStringAlpha(creditCard.cardHolder)) {
    errors.push('cardHolder');
  }
  if (!isCardNumberValid(creditCard.cardNumber)) {
    errors.push('cardNumber');
  }
  if (!isExpiryValid(creditCard.expiry)) {
    errors.push('expiry');
  }
  if (!isCVCValid(creditCard)) {
    errors.push('cvc');
  }

  return errors;
}

async function newBookingRequest(getState) {
  const { booking } = getState();
  const { user } = getState();
  const {
    roomTypes,
    payment,
    joinRemembered,
    checkIn,
    checkOut,
    hotel,
    flash,
    includeBreakfast,
    includeSmoking,
    includePetFriendly,
    promoCode,
    sundrySales,
    promotion,
    mayaPayment,
    creditCard: {
      cardHolder,
      cardFirstNameHolder,
      cardLastNameHolder,
      cardNumber,
      expiry,
      cvc,
    },
    // wallet: { source, phone },
    profile: {
      nameTitle,
      firstName,
      lastName,
      birthday,
      country,
      preferLang,
      phoneCountry,
      email,
    },
    bookingId,
    bookingNumber,
  } = booking;
  const rooms = [];
  const nights = checkOut.diff(checkIn, 'd');
  roomTypes.forEach(roomType => {
    if (roomType.numberOfRooms > 0) {
      let price = 0;
      const adultPerRoom = Math.floor(roomType.adults / roomType.numberOfRooms);
      let remainAdults =
        roomType.adults - roomType.numberOfRooms * adultPerRoom;
      let remainChildren =
        roomType.children -
        roomType.numberOfRooms *
          Math.floor(roomType.children / roomType.numberOfRooms);
      for (let i = 0; i < roomType.numberOfRooms; i += 1) {
        const addAdults = adultPerRoom + remainAdults - roomType.limitGuest;
        if (payment === PAYMENT_CREDIT_CARD || payment === PAYMENT_WALLET) {
          if (joinRemembered) {
            price = roomType.price.member;
            price += addAdults * roomType.price.adultMember;
          } else {
            price = roomType.price.normal;
            price += addAdults * roomType.price.adultNormal;
          }
        } else if (payment === PAYMENT_PAY_AT_HOTEL) {
          if (joinRemembered) {
            price = roomType.price.memberHotel;
            price += addAdults * roomType.price.adultMemberHotel;
          } else {
            price = roomType.price.normalHotel;
            price += addAdults * roomType.price.adultNormalHotel;
          }
        }
        const room = {
          room_id: roomType.room.id,
          rate_id: roomType.room.rate.id,
          amount: price,
          guests: [],
        };
        const guest = {
          name_title_id: nameTitle,
          first_name: firstName,
          last_name: lastName,
          country_of_residence_code: country,
          prefer_lang: preferLang,
          phoneCountry,
          birthday: birthday && birthday.format('YYYY-MM-DD'),
          email,
        };
        if (includeSmoking) {
          room.amount += hotel.smoking.price_per_night * nights;
        }
        if (includePetFriendly) {
          room.amount += hotel.pet_friendly.price_per_night * nights;
        }
        for (let adult = 0; adult < adultPerRoom; adult += 1) {
          room.guests.push({
            ...guest,
            type: 'Adult',
          });
          if (includeBreakfast) {
            room.amount += hotel.breakfast.price_per_guest * nights;
          }
        }
        for (remainAdults; remainAdults > 0; remainAdults -= 1) {
          room.guests.push({
            ...guest,
            type: 'Adult',
          });
          if (includeBreakfast) {
            room.amount += hotel.breakfast.price_per_guest * nights;
          }
        }
        const childrenPerRoom = Math.floor(
          roomType.children / roomType.numberOfRooms,
        );
        for (let child = 0; child < childrenPerRoom; child += 1) {
          room.guests.push({
            ...guest,
            type: 'Child',
          });
          if (includeBreakfast) {
            room.amount += hotel.breakfast.price_per_guest * nights;
          }
        }
        for (remainChildren; remainChildren > 0; remainChildren -= 1) {
          room.guests.push({
            ...guest,
            type: 'Child',
          });
          if (includeBreakfast) {
            room.amount += hotel.breakfast.price_per_guest * nights;
          }
        }
        rooms.push(room);
      }
    }
  });
  const cookies = new Cookies();
  const utmSource = cookies.get('utm_source');
  const utmCampaign = cookies.get('utm_campaign');
  const utmMedium = cookies.get('utm_medium');
  const utmContent = cookies.get('utm_content');
  const utmTerm = cookies.get('utm_terms');
  let bookingRequest = {
    // session_id: mp.getId(),
    // mixpanel_distinct_id: mp.getId(),
    check_in: checkIn.format('YYYY-MM-DD'),
    check_out: checkOut.format('YYYY-MM-DD'),
    hotel_id: hotel.id,
    flash_sale_code: flash,
    breakfast: includeBreakfast ? hotel.breakfast : null,
    smoking: includeSmoking ? hotel.smoking : null,
    pet_friendly: includePetFriendly ? hotel.pet_friendly : null,
    payment_method: payment,
    rooms,
    utm_source: utmSource,
    utm_campaign: utmCampaign,
    utm_medium: utmMedium,
    utm_content: utmContent,
    utm_term: utmTerm,
    maya_payment: mayaPayment,
    bookingId,
    bookingNumber,
    email,
    additional_products: [],
    hotel_country_code: hotel.country_code,
  };
  if (promotion && promoCode) {
    bookingRequest = {
      ...bookingRequest,
      promotion: promotion ? promoCode : '',
    };
  }
  if (sundrySales.length) {
    sundrySales.forEach(item => {
      if (item.numberOfItem > 0) {
        bookingRequest.additional_products.push({
          id: item.item.id,
          amount: item.item.price * item.numberOfItem,
          quantity: item.numberOfItem,
        });
      }
    });
  }
  let errors = [];
  if (payment === PAYMENT_CREDIT_CARD && mayaPayment === '') {
    errors = creditCardValidate({
      cardHolder,
      cardNumber,
      expiry,
      cvc,
    });
  }
  if (
    payment === PAYMENT_CREDIT_CARD &&
    mayaPayment === '' &&
    errors.length === 0
  ) {
    const cardExpiry = cardValidator.expirationDate(expiry);

    const year =
      cardExpiry.year.length === 2 ? `20${cardExpiry.year}` : cardExpiry.year;
    let cardData = null;
    let token;
    if (hotel.country_code === 'PH') {
      cardData = {
        number: cardNumber,
        cvc,
        holderName: cardHolder,
        first_name: cardFirstNameHolder,
        last_name: cardLastNameHolder,
        expiry,
      };
      token = await createTokenPaymentMaya(window.App.mayaApiKey, cardData);
    } else {
      cardData = {
        number: cardNumber,
        cvc,
        holderName: cardHolder,
        expiryMonth: cardExpiry.month,
        expiryYear: year,
      };
      token = await createTokenPaymentOmise(window.App.omiseApiKey, cardData);
    }
    // const adyen = await adyenEncrypt.encrypt(window.App.adyenKey, cardData);
    const suffix = cardNumber.substring(cardNumber.length - 4);
    const matched = cardNumber.match(/(^\d{6})(\d{5,})(\d{4})/);
    const masked = matched[1] + matched[2].replace(/\w/g, 'X') + matched[3];
    const cardType = cardValidator.number(cardNumber).card.type;
    const cardTypeMap = {
      visa: 'visa',
      'master-card': 'master',
      jcb: 'jcb',
      unionpay: 'cup',
      'american-express': 'amex',
    };
    bookingRequest = {
      ...bookingRequest,
      'payment-encrypted-data': token,
      suffix_credit_card: suffix,
      masked_credit_card: masked,
      credit_card_type: cardTypeMap[cardType],
      billing_name: cardHolder,
      expiry_year: cardExpiry.year,
      expiry_month: cardExpiry.month,
    };
  }

  if (joinRemembered && !user.isLoggedIn) {
    bookingRequest = {
      ...bookingRequest,
      sign_up: true,
    };
  }

  if (bookingId) {
    bookingRequest = {
      ...bookingRequest,
      isMemberBooking: joinRemembered,
    };
  }
  return bookingRequest;
}

export function applyPromoCode(promoCode, applyingForBin) {
  return async (dispatch, getState, { client }) => {
    const state = getState();
    const { booking } = state;
    const supportedPayment = booking.hotel.payment_method;

    dispatch({
      type: APPLY_PROMO_CODE,
      payload: {
        promoCode,
        applyingForBin,
        supportedPayment,
      },
    });
    if (promoCode !== '') {
      const bookingRequest = await newBookingRequest(getState);
      const Booking = gql`mutation Booking{
        applyPromoCode(booking: "${encodeURIComponent(
          JSON.stringify({ ...bookingRequest, promotion: promoCode }),
        )}"){
      type
        title
        credit_card{
        tax
        price
        discounted
        member_tax
        member_price
        member_discounted
      }
      pay_later{
        tax
        price
        discounted
        member_tax
        member_price
        member_discounted
      }
      }
      }`;

      const { data, errors } = await client.networkInterface.query({
        query: Booking,
      });
      if (!errors) {
        const promotion = data.applyPromoCode;
        const supportedPayment = [];
        if (promotion.credit_card) {
          supportedPayment.push(PAYMENT_CREDIT_CARD);
          if (
            booking.hotel.payment_method_maya.indexOf(PAYMENT_MAYA_WALLET) > -1
          ) {
            supportedPayment.push(PAYMENT_MAYA_WALLET);
          }
        }
        if (promotion.wallet) {
          supportedPayment.push(PAYMENT_WALLET);
        }
        if (promotion.pay_later) {
          supportedPayment.push(PAYMENT_PAY_AT_HOTEL);
        }
        dispatch({
          type: SUCCESS_PROMO_CODE,
          payload: {
            promotion,
            supportedPayment,
          },
        });
      } else if (!applyingForBin) {
        const respError = JSON.parse(errors[0].message);
        const errorMessage = respError.message;
        dispatch({
          type: FAIL_PROMO_CODE,
          payload: {
            errorMessage,
            errors: ['promoCode'],
          },
        });
      }
    }

    return booking;
  };
}

export function changeWalletMethod(wallet) {
  return async (dispatch, getState) => {
    const { booking } = getState();
    if (booking.payment === PAYMENT_WALLET) {
      dispatch({
        type: SUCCESS_WALLET,
        payload: wallet,
      });
    } else {
      dispatch({
        type: FAIL_WALLET,
        payload: wallet,
      });
    }
    return booking;
  };
}

export function addCreditCard(creditCard) {
  return async (dispatch, getState) => {
    const { booking } = getState();
    if (booking.payment === PAYMENT_CREDIT_CARD) {
      const errors = creditCardValidate(creditCard);
      if (creditCard.cardNumber.length > 6 && booking.promotion === null) {
        const promoCode = `${creditCard.cardNumber.substring(0, 6)}XXXXXXXXXX`;
        dispatch(applyPromoCode(promoCode, true));
      } else if (
        creditCard.cardNumber.length <= 6 &&
        booking.promotion !== null &&
        booking.applyingForBin
      ) {
        dispatch(applyPromoCode('', true));
      }
      if (errors.length > 0) {
        dispatch({
          type: FAIL_CREDIT_CARD,
          payload: errors,
        });
      } else {
        dispatch({
          type: SUCCESS_CREDIT_CARD,
          payload: creditCard,
        });
      }
    }
    return booking;
  };
}

export function getPrices(
  {
    hotel: { breakfast, smoking, pet_friendly },
    payment,
    roomTypes,
    checkIn,
    checkOut,
    includeBreakfast,
    includeSmoking,
    includePetFriendly,
    sundrySales,
    joinRemembered,
    adults,
    children,
    promotion,
  },
  { isLoggedIn },
) {
  const nights = checkOut.diff(checkIn, 'd');
  let normalRoomCharge = 0;
  let memberRoomCharge = 0;
  let normalRoomChargeNoTax = 0;
  let memberRoomChargeNoTax = 0;
  let normalCardRoomCharge = 0;
  let memberCardRoomCharge = 0;
  let normalHotelRoomCharge = 0;
  let memberHotelRoomCharge = 0;
  roomTypes.forEach(roomType => {
    const addAdults = adults - roomType.limitGuest * roomType.numberOfRooms;
    if (roomType.numberOfRooms > 0) {
      if (
        payment === PAYMENT_CREDIT_CARD ||
        payment === PAYMENT_WALLET ||
        payment === PAYMENT_MAYA_WALLET ||
        payment === PAYMENT_MAYA_QRPH
      ) {
        memberRoomCharge +=
          roomType.price.member * roomType.numberOfRooms +
          roomType.price.adultMember * addAdults;
        memberRoomChargeNoTax +=
          roomType.price.memberNoTax * roomType.numberOfRooms +
          roomType.price.adultMemberNoTax * addAdults;

        normalRoomCharge +=
          roomType.price.normal * roomType.numberOfRooms +
          roomType.price.adultNormal * addAdults;
        normalRoomChargeNoTax +=
          roomType.price.normalNoTax * roomType.numberOfRooms +
          roomType.price.adultNormalNoTax * addAdults;
      } else if (payment === PAYMENT_PAY_AT_HOTEL) {
        memberRoomCharge +=
          roomType.price.memberHotel * roomType.numberOfRooms +
          roomType.price.adultMemberHotel * addAdults;
        memberRoomChargeNoTax +=
          roomType.price.memberHotelNoTax * roomType.numberOfRooms +
          roomType.price.adultMemberHotelNoTax * addAdults;

        normalRoomCharge +=
          roomType.price.normalHotel * roomType.numberOfRooms +
          roomType.price.adultNormalHotel * addAdults;
        normalRoomChargeNoTax +=
          roomType.price.normalHotelNoTax * roomType.numberOfRooms +
          roomType.price.adultNormalHotelNoTax * addAdults;
      }

      memberCardRoomCharge +=
        roomType.price.member * roomType.numberOfRooms +
        roomType.price.adultMember * addAdults;

      normalCardRoomCharge +=
        roomType.price.normal * roomType.numberOfRooms +
        roomType.price.adultNormal * addAdults;

      memberHotelRoomCharge +=
        roomType.price.memberHotel * roomType.numberOfRooms +
        roomType.price.adultMemberHotel * addAdults;

      normalHotelRoomCharge +=
        roomType.price.normalHotel * roomType.numberOfRooms +
        roomType.price.adultNormalHotel * addAdults;
    }
  });

  let discounted = 0;
  let memberDiscounted = 0;
  let memberDiscountedCard = 0;
  let discountedCard = 0;
  let memberDiscountedHotel = 0;
  let discountedHotel = 0;
  if (promotion) {
    if (
      (payment === PAYMENT_CREDIT_CARD ||
        payment === PAYMENT_WALLET ||
        payment === PAYMENT_MAYA_WALLET ||
        payment === PAYMENT_MAYA_QRPH) &&
      promotion.credit_card
    ) {
      discounted =
        promotion.credit_card.price - promotion.credit_card.discounted;
      memberDiscounted =
        promotion.credit_card.member_price -
        promotion.credit_card.member_discounted;
    }
    if (payment === PAYMENT_PAY_AT_HOTEL && promotion.pay_later) {
      discounted = promotion.pay_later.price - promotion.pay_later.discounted;
      memberDiscounted =
        promotion.pay_later.member_price -
        promotion.pay_later.member_discounted;
    }
    if (promotion.credit_card) {
      discountedCard =
        promotion.credit_card.price - promotion.credit_card.discounted;
      memberDiscountedCard =
        promotion.credit_card.member_price -
        promotion.credit_card.member_discounted;
    }
    if (promotion.pay_later) {
      discountedHotel =
        promotion.pay_later.price - promotion.pay_later.discounted;
      memberDiscountedHotel =
        promotion.pay_later.member_price -
        promotion.pay_later.member_discounted;
    }
  }

  let roomCharge = 0;
  let roomChargeNoTax = 0;
  if (joinRemembered || isLoggedIn) {
    roomCharge = memberRoomCharge;
    roomChargeNoTax = memberRoomChargeNoTax;
  } else {
    roomCharge = normalRoomCharge;
    roomChargeNoTax = normalRoomChargeNoTax;
  }

  const taxAndServices = roomCharge - roomChargeNoTax;
  let breakfastPrice = 0;
  let smokingPrice = 0;
  let petFriendlyPrice = 0;
  if (includeBreakfast && breakfast) {
    breakfastPrice = breakfast.price_per_guest * (adults + children) * nights;
  }
  if (includeSmoking && smoking) {
    smokingPrice = smoking.price_per_night * nights;
  }
  if (includePetFriendly && pet_friendly) {
    petFriendlyPrice = pet_friendly.price_per_night * nights;
  }
  let sundryPrice = 0;
  sundrySales.forEach(item => {
    sundryPrice += item.item.price * item.numberOfItem;
  });
  let beforePromotionNormal = 0;
  let beforePromotionMember = 0;
  if (discounted && memberDiscounted) {
    beforePromotionNormal = normalRoomCharge + breakfastPrice;
    beforePromotionMember = memberRoomCharge + breakfastPrice;
  }
  const totalNormal =
    normalRoomCharge +
    breakfastPrice +
    smokingPrice +
    petFriendlyPrice +
    sundryPrice -
    discounted;
  const totalMember =
    memberRoomCharge +
    breakfastPrice +
    smokingPrice +
    petFriendlyPrice +
    sundryPrice -
    memberDiscounted;
  const totalNormalCard =
    normalCardRoomCharge +
    breakfastPrice +
    smokingPrice +
    petFriendlyPrice +
    sundryPrice -
    discountedCard;
  const totalMemberCard =
    memberCardRoomCharge +
    breakfastPrice +
    smokingPrice +
    petFriendlyPrice +
    sundryPrice -
    memberDiscountedCard;
  const totalNormalHotel =
    normalHotelRoomCharge +
    breakfastPrice +
    smokingPrice +
    petFriendlyPrice +
    sundryPrice -
    discountedHotel;
  const totalMemberHotel =
    memberHotelRoomCharge +
    breakfastPrice +
    smokingPrice +
    petFriendlyPrice +
    sundryPrice -
    memberDiscountedHotel;

  return {
    normalRoomCharge,
    memberRoomCharge,
    normalRoomChargeNoTax,
    memberRoomChargeNoTax,
    discounted,
    memberDiscounted,
    memberDiscountedCard,
    discountedCard,
    memberDiscountedHotel,
    discountedHotel,
    roomCharge,
    roomChargeNoTax,
    taxAndServices,
    breakfastPrice,
    smokingPrice,
    petFriendlyPrice,
    beforePromotionNormal,
    beforePromotionMember,
    totalNormal,
    totalMember,
    totalNormalCard,
    totalMemberCard,
    totalNormalHotel,
    totalMemberHotel,
  };
}

// export function checkWalletOmise(booking) {
//   const priceData = {
//     amount: 621,
//     currency: 'THB',
//   };
//   return priceData;
// }

// export function submitBookingWithMaya() {
//   return async (dispatch, getState, { client, history }) => {
//     const state = getState();
//     const { booking } = state;
//     if (booking.isSubmittingBooking || booking.errorMessage) {
//       return;
//     }
//     dispatch({
//       type: SUBMIT_BOOKING,
//     });
//     const bookingRequest = await newBookingRequest(getState);
//     const Booking = gql`mutation Booking{
//       submitBooking(booking: "${encodeURIComponent(
//         JSON.stringify(bookingRequest),
//       )}"){
//     booking_code
//     ics
//     booking_id
//     access_token
//     maya {
//       validate3DsMaya {
//         dataMaya {
//           authorizeUri
//         }
//       }
//     }
//     }
//     }`;
//     const { totalNormalCard } = getPrices(booking, state.user);
//     const errorsCreditCard = [];
//     if (booking.payment === PAYMENT_CREDIT_CARD && totalNormalCard > 0) {
//       errorsCreditCard.push(...creditCardValidate(booking.creditCard));
//     }

//     if (errorsCreditCard.length > 0) {
//       dispatch({
//         type: FAIL_SUBMIT_BOOKING,
//         payload: {
//           errors: errorsCreditCard,
//           errorMessage: '',
//         },
//       });
//       return;
//     }

//     if (window.sessionStorage) {
//       window.sessionStorage.setItem(
//         'bookingFailed',
//         btoa(unescape(encodeURIComponent(JSON.stringify(getState().booking)))),
//       );
//     }

//     const { data, errors } = await client.networkInterface.query({
//       query: Booking,
//     });
//     if (!errors) {
//       dispatch({
//         type: SUCCESS_SUBMIT_BOOKING,
//         payload: data.submitBooking,
//       });

//       if (data.submitBooking.access_token) {
//         dispatch(loginWithToken(data.submitBooking.access_token));
//       }
//       if (window.sessionStorage) {
//         window.sessionStorage.setItem(
//           'bookingSuccess',
//           btoa(
//             unescape(encodeURIComponent(JSON.stringify(getState().booking))),
//           ),
//         );
//       }
//       if (
//         (booking.payment === PAYMENT_CREDIT_CARD &&
//           data.submitBooking.maya &&
//           data.submitBooking.maya.validate3DsMaya &&
//           data.submitBooking.maya.validate3DsMaya.dataMaya) ||
//         booking.payment === PAYMENT_WALLET
//       ) {
//         history.push({
//           pathname: `/booking/redirect-to-issuer`,
//         });
//         return;
//       }
//       if (window.sessionStorage) {
//         window.sessionStorage.removeItem('bookingFailed');
//         window.sessionStorage.removeItem('bookingSuccess');
//       }

//       history.push({
//         pathname: `/booking/confirmation/`,
//         search: `confirmation=${data.submitBooking.booking_id}`,
//       });
//     } else {
//       const respError = JSON.parse(errors[0].message);
//       let errorMessage = `${respError.message}<br/>`;
//       const errorFields = [];
//       if (respError.error) {
//         Object.keys(respError.error).forEach(key => {
//           errorMessage += `•${key}: ${respError.error[key]}<br/>`;
//           errorFields.push(key);
//         });
//       }
//       if (respError.data && respError.data.access_token) {
//         dispatch(loginWithToken(respError.data.access_token));
//       }
//       dispatch({
//         type: FAIL_SUBMIT_BOOKING,
//         payload: {
//           errors: errorFields,
//           errorMessage,
//           oldEmail: booking.profile.email,
//           oldBirthday: booking.profile.birthday,
//         },
//       });
//       if (
//         respError.error &&
//         (respError.error.email || respError.error.birthday)
//       ) {
//         history.goBack();
//       }
//     }
//   };
// }

// export function submitBookingWithOmise(omiseTokenId) {
//   return async (dispatch, getState, { client, history }) => {
//     const state = getState();
//     const { booking } = state;
//     if (booking.isSubmittingBooking || booking.errorMessage) {
//       return;
//     }
//     dispatch({
//       type: SUBMIT_BOOKING,
//     });
//     const bookingRequest = await newBookingRequest(getState, omiseTokenId);
//     const Booking = gql`mutation Booking{
//       submitBooking(booking: "${encodeURIComponent(
//         JSON.stringify(bookingRequest),
//       )}"){
//     booking_code
//     ics
//     booking_id
//     access_token
//     omise {
//       validate3DsOmise {
//         dataOmise {
//           authorizeUri
//         }
//       }
//     }
//     }
//     }`;
//     const { totalNormalCard } = getPrices(booking, state.user);
//     const errorsCreditCard = [];
//     if (booking.payment === PAYMENT_CREDIT_CARD && totalNormalCard > 0) {
//       errorsCreditCard.push(...creditCardValidate(booking.creditCard));
//     }

//     if (errorsCreditCard.length > 0) {
//       dispatch({
//         type: FAIL_SUBMIT_BOOKING,
//         payload: {
//           errors: errorsCreditCard,
//           errorMessage: '',
//         },
//       });
//       return;
//     }

//     if (window.sessionStorage) {
//       window.sessionStorage.setItem(
//         'bookingFailed',
//         btoa(unescape(encodeURIComponent(JSON.stringify(getState().booking)))),
//       );
//     }

//     const { data, errors } = await client.networkInterface.query({
//       query: Booking,
//     });
//     if (!errors) {
//       dispatch({
//         type: SUCCESS_SUBMIT_BOOKING,
//         payload: data.submitBooking,
//       });

//       if (data.submitBooking.access_token) {
//         dispatch(loginWithToken(data.submitBooking.access_token));
//       }
//       if (window.sessionStorage) {
//         window.sessionStorage.setItem(
//           'bookingSuccess',
//           btoa(
//             unescape(encodeURIComponent(JSON.stringify(getState().booking))),
//           ),
//         );
//       }
//       if (
//         (booking.payment === PAYMENT_CREDIT_CARD &&
//           data.submitBooking.omise &&
//           data.submitBooking.omise.validate3DsOmise &&
//           data.submitBooking.omise.validate3DsOmise.dataOmise) ||
//         booking.payment === PAYMENT_WALLET
//       ) {
//         history.push({
//           pathname: `/booking/redirect-to-issuer`,
//         });
//         return;
//       }
//       if (window.sessionStorage) {
//         window.sessionStorage.removeItem('bookingFailed');
//         window.sessionStorage.removeItem('bookingSuccess');
//       }

//       history.push({
//         pathname: `/booking/confirmation/`,
//         search: `confirmation=${data.submitBooking.booking_id}`,
//       });
//     } else {
//       const respError = JSON.parse(errors[0].message);
//       let errorMessage = `${respError.message}<br/>`;
//       const errorFields = [];
//       if (respError.error) {
//         Object.keys(respError.error).forEach(key => {
//           errorMessage += `•${key}: ${respError.error[key]}<br/>`;
//           errorFields.push(key);
//         });
//       }
//       if (respError.data && respError.data.access_token) {
//         dispatch(loginWithToken(respError.data.access_token));
//       }
//       dispatch({
//         type: FAIL_SUBMIT_BOOKING,
//         payload: {
//           errors: errorFields,
//           errorMessage,
//           oldEmail: booking.profile.email,
//           oldBirthday: booking.profile.birthday,
//         },
//       });
//       if (
//         respError.error &&
//         (respError.error.email || respError.error.birthday)
//       ) {
//         history.goBack();
//       }
//     }
//   };
// }

export function submitBooking() {
  return async (dispatch, getState, { client, history }) => {
    const state = getState();
    const { booking } = state;
    if (booking.isSubmittingBooking || booking.errorMessage) {
      return;
    }
    dispatch({
      type: SUBMIT_BOOKING,
    });
    const bookingRequest = await newBookingRequest(getState);
    const Booking = gql`mutation Booking{
      submitBooking(booking: "${encodeURIComponent(
        JSON.stringify(bookingRequest),
      )}"){
    booking_code
    ics
    booking_id
    access_token
    omise {
      validate3DsOmise {
        dataOmise {
          authorizeUri
        }
      }
    }
    maya {
      validate3DsMaya {
        dataMaya {
          authorizeUri
        }
      }
    }
    }
    }`;
    if (window.sessionStorage) {
      window.sessionStorage.setItem(
        'bookingFailed',
        btoa(unescape(encodeURIComponent(JSON.stringify(getState().booking)))),
      );
    }

    const { data, errors } = await client.networkInterface.query({
      query: Booking,
    });
    if (!errors) {
      dispatch({
        type: SUCCESS_SUBMIT_BOOKING,
        payload: data.submitBooking,
      });

      if (data.submitBooking.access_token) {
        dispatch(loginWithToken(data.submitBooking.access_token));
      }
      if (window.sessionStorage) {
        window.sessionStorage.setItem(
          'bookingSuccess',
          btoa(
            unescape(encodeURIComponent(JSON.stringify(getState().booking))),
          ),
        );
      }

      // if (payment_option === )

      if (
        booking.payment === PAYMENT_CREDIT_CARD &&
        data.submitBooking.omise &&
        data.submitBooking.omise.validate3DsOmise &&
        data.submitBooking.omise.validate3DsOmise.dataOmise
      ) {
        history.push({
          pathname: `/booking/redirect-to-issuer`,
        });
        return;
      }

      if (
        booking.payment === PAYMENT_CREDIT_CARD &&
        data.submitBooking.maya &&
        data.submitBooking.maya.validate3DsMaya &&
        data.submitBooking.maya.validate3DsMaya.dataMaya
      ) {
        history.push({
          pathname: `/booking/redirect-to-issuer`,
        });
        return;
      }

      if (window.sessionStorage) {
        window.sessionStorage.removeItem('bookingFailed');
        window.sessionStorage.removeItem('bookingSuccess');
      }

      history.push({
        pathname: `/booking/confirmation/`,
        search: `confirmation=${data.submitBooking.booking_id}`,
      });
    } else {
      const respError = JSON.parse(errors[0].message);
      let errorMessage = `${respError.message}<br/>`;
      const errorFields = [];
      if (respError.error) {
        Object.keys(respError.error).forEach(key => {
          errorMessage += `•${key}: ${respError.error[key]}<br/>`;
          errorFields.push(key);
        });
      }
      if (respError.data && respError.data.access_token) {
        dispatch(loginWithToken(respError.data.access_token));
      }
      dispatch({
        type: FAIL_SUBMIT_BOOKING,
        payload: {
          errors: errorFields,
          errorMessage,
          oldEmail: booking.profile.email,
          oldBirthday: booking.profile.birthday,
        },
      });
      if (
        respError.error &&
        (respError.error.email || respError.error.birthday)
      ) {
        history.goBack();
      }
    }
  };
}

export function resendBookingConfirmation(
  bookingNumber,
  email,
  toEmail,
  successMessage,
  failMessage,
) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: RESEND_BOOKING_CONFIRMATION,
    });
    if (!bookingNumber || !email || !toEmail) {
      dispatch({
        type: FAIL_RESEND_BOOKING_CONFIRMATION,
        payload: failMessage,
      });
      dispatch(closeResendBookingModal());
      dispatch(addFlashMessage(failMessage, TYPE_ERROR));
      return;
    }
    const Resend = gql`mutation Resend{
      resendBookingConfirmation(bookingNumber: "${bookingNumber}" email: "${email}" toEmail: "${toEmail}")
    }`;
    const { errors } = await client.networkInterface.query({
      query: Resend,
    });
    if (typeof errors === 'undefined') {
      dispatch({
        type: SUCCESS_RESEND_BOOKING_CONFIRMATION,
      });
      dispatch(closeResendBookingModal());
      dispatch(addFlashMessage(successMessage, TYPE_OK));
    } else {
      let respError = '';
      try {
        respError = JSON.parse(errors[0].message);
      } catch (e) {
        respError = errors[0]; // eslint-disable-line
      }
      dispatch({
        type: FAIL_RESEND_BOOKING_CONFIRMATION,
        payload: respError.message,
      });
    }
  };
}

export function getBookings(email, confirmationCode, errorMessage) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: GET_BOOKINGS_START,
    });
    if (!email || !confirmationCode) {
      email = '';
      confirmationCode = '';
      if (!getState().user.isLoggedIn) {
        dispatch({
          type: GET_BOOKINGS_FAIL,
          payload: {
            errorMessage: errorMessage.message,
            errors: ['email'],
          },
        });
        return false;
      }
    }
    const GetBookings = gql`query GetBookings{
      getBookings(email: "${email}" confirmationCode: "${confirmationCode}"){
        current {
          booking_id
          booking_code
          check_in
          check_out
          status
          amount
          is_editable
          is_owner
          check_in_data {
            check_in_status
            check_in_available_date
            retry_step
          }
          hotel {
            id
            name
            currency
            coordinate
          }
          breakfast {
            name
            price_per_guest
          }
          smoking {
            hotel_id
            price_per_night
          }
          pet_friendly {
            hotel_id
            price_per_night
          }
          additional_products {
            id
            amount
            quantity
            price
            detail {
              title
            }
          }
          rooms {
            name
            room_id
            guests {
              email
              name_title
              first_name
              last_name
              type
            }
          }
        }
        previous {
          booking_id
          booking_code
          check_in
          check_out
          status
          amount
          is_editable
          is_owner
          check_in_data {
            check_in_status
            check_in_available_date
            retry_step
          }
          hotel {
            id
            name
            currency
          }
          breakfast {
            name
            price_per_guest
          }
          smoking {
            hotel_id
            price_per_night
          }
          additional_products {
            id
            amount
            quantity
            price
            detail {
              title
            }
          }
          rooms {
            name
            room_id
            guests {
              email
              name_title
              first_name
              last_name
              type
            }
          }
        }
        canceled {
          booking_id
          booking_code
          check_in
          check_out
          status
          amount
          is_editable
          is_owner
          check_in_data {
            check_in_status
            check_in_available_date
            retry_step
          }
          hotel {
            id
            name
            currency
          }
          breakfast {
            name
            price_per_guest
          }
          smoking {
            hotel_id
            price_per_night
          }
          additional_products {
            id
            amount
            quantity
            price
            detail {
              title
            }
          }
          rooms {
            name
            room_id
            guests {
              email
              name_title
              first_name
              last_name
              type
            }
          }
        }
        all {
          booking_id
          booking_code
          check_in
          check_out
          status
          amount
          is_editable
          is_owner
          hotel {
            id
            name
            currency
          }
          breakfast {
            name
            price_per_guest
          }
          smoking {
            hotel_id
            price_per_night
          }
          additional_products {
            id
            amount
            quantity
            price
            detail {
              title
            }
          }
          rooms {
            name
            room_id
            guests {

              name_title
              first_name
              last_name
              type
            }
          }
        }
      }
    }`;
    const { data, errors } = await client.networkInterface.query({
      query: GetBookings,
    });
    if (typeof errors === 'undefined') {
      if (email && confirmationCode && data.getBookings.all.length === 0) {
        // Can't find bookings
        dispatch({
          type: GET_BOOKINGS_FAIL,
          payload: {
            errorMessage: errorMessage.message,
            errors: ['email'],
          },
        });
      } else {
        dispatch({
          type: GET_BOOKINGS_SUCCESS,
          payload: {
            myBookings: data.getBookings,
          },
        });
        return true;
      }
    } else {
      let respError = '';
      try {
        respError = JSON.parse(errors[0].message);
      } catch (e) {
        if (typeof errors !== 'undefined') {
          respError = errors[0]; // eslint-disable-line
        } else {
          respError = errorMessage;
        }
      }
      dispatch({
        type: GET_BOOKINGS_FAIL,
        payload: {
          errorMessage: respError.message,
          errors: ['email'],
        },
      });
    }
    return false;
  };
}

export function cancelBooking(
  bookingId,
  email,
  confirmationCode,
  successMessage,
) {
  return async (dispatch, getState, { client }) => {
    await dispatch({
      type: DELETE_BOOKINGS_START,
    });
    const CancelBooking = gql`mutation CancelBooking{
      cancelBooking(bookingId: "${bookingId}" email: "${email}" confirmationCode: "${confirmationCode}")
    }`;
    const { errors } = await client.networkInterface.query({
      query: CancelBooking,
    });
    if (typeof errors === 'undefined') {
      await dispatch({
        type: DELETE_BOOKINGS_SUCCESS,
      });
      dispatch(addFlashMessage(successMessage, TYPE_OK));
    } else {
      await dispatch({
        type: DELETE_BOOKINGS_FAIL,
      });
      dispatch(addFlashMessage(errors[0].message, TYPE_ERROR));
    }
  };
}

export function editBooking(
  oldBookingId,
  email,
  confirmationCode,
  nCheckIn,
  nCheckOut,
  nNumberOfRooms,
  nAdults,
  nChildren,
  nIncludeBreakfast,
  nIncludeSmoking,
  nIncludePetFriendly,
) {
  return async (dispatch, getState, { client }) => {
    const GetOneBooking = gql`query GetOneBooking{
      getOneBooking(bookingId: "${oldBookingId}" email: "${email}" confirmationCode: "${confirmationCode}"){
        booking_id
        booking_code
        check_in
        check_out
        amount
        is_editable
        is_owner
        payment_method
        payment_method_maya
        hotel {
          id
          name
          currency
          coordinate
        }
        breakfast {
          name
          price_per_guest
        }
        smoking {
          hotel_id
          price_per_night
        }
        pet_friendly {
          hotel_id
          price_per_night
        }
        additional_products {
          id
          quantity
        }
        rooms {
          name
          room_id
          guests {
            email
            name_title_id
            first_name
            last_name
            type
            phoneCountry {
              phone
              country
            }
            country_of_residence_code
            prefer_lang
          }
        }
      }
    }`;
    const { data, error } = await client.networkInterface.query({
      query: GetOneBooking,
    });
    if (error) {
      throw error;
    }
    const oldBooking = data.getOneBooking;
    let booking = { ...initialBooking };
    const { user } = getState();
    const numberOfRooms = nNumberOfRooms || oldBooking.rooms.length;
    const hotelId = oldBooking.hotel.id;
    const checkIn = nCheckIn || moment(oldBooking.check_in);
    const checkOut = nCheckOut || moment(oldBooking.check_out);
    const birthday = user.isLoggedIn
      ? moment(user.user.birthday).format('YYYY-MM-DD')
      : '';
    const HotelQuery = gql`query Hotel{
      hotel(hotelId: ${hotelId} checkIn: "${checkIn.format(
      'YYYY-MM-DD',
    )}" checkOut: "${checkOut.format('YYYY-MM-DD')}" birthday: "${birthday}"){
    id
    seekda_id
    name
    name_en
    slug_name
    currency
    code
    address
    phone
    email
    is_birthday_remembered
    images
    country_code
    payment_method
    payment_method_maya
    coordinate
    location{
    parent
    }
    breakfast{
      name
      price_per_guest
      price_per_guest_no_tax
    }
    policy_guarantee{
      pay_now_rules
      pay_later_rules
      expire_time
    }
    smoking {
      hotel_id
      price_per_night
    }
    pet_friendly {
      hotel_id
      price_per_night
    }
    flash_sale {
      payment_methods
    }
    rooms{
      id
      name
      code
      description
      images
      available
      limit_capacity
      guest_combination{
        adult
        child
      }
      rate {
        id
        code
        available_rates {
          limit_guest
          selling_rate_non_member {
            price
            additional_amount_adult
            additional_amount_child
            price_no_tax
            additional_amount_adult_no_tax
            additional_amount_child_no_tax
          }
          selling_rate_member {
            price
            additional_amount_adult
            additional_amount_child
            price_no_tax
            additional_amount_adult_no_tax
            additional_amount_child_no_tax
          }
          selling_rate_non_member_pay_at_hotel{
            price
            additional_amount_adult
            additional_amount_child
            price_no_tax
            additional_amount_adult_no_tax
            additional_amount_child_no_tax
          }
          selling_rate_member_pay_at_hotel{
            price
            additional_amount_adult
            additional_amount_child
            price_no_tax
            additional_amount_adult_no_tax
            additional_amount_child_no_tax
          }
        }
      }
    }
    }
    }`;
    const {
      data: { hotel },
    } = await client.networkInterface.query({
      query: HotelQuery,
    });
    const SundrySale = gql`query SundrySale{
    getSundrySale(hotelId: ${hotelId}){
    id
    title
    description
    price
    max_item_per_booking
    image_thumb
    image_full
    start
    end
    }
    }`;
    const {
      data: { getSundrySale },
    } = await client.networkInterface.query({
      query: SundrySale,
    });
    const sundrySales = [];
    getSundrySale.forEach(item => {
      let numberOfItem = 0;
      if (oldBooking.additional_products) {
        oldBooking.additional_products.forEach(a => {
          if (a.id === item.id) {
            numberOfItem = a.quantity;
          }
        });
      }
      sundrySales.push({
        item,
        numberOfItem,
      });
    });
    let bf = oldBooking.breakfast;
    let sm = oldBooking.smoking;
    let pf = oldBooking.pet_friendly;
    if (typeof nIncludeBreakfast !== 'undefined') {
      bf = nIncludeBreakfast === '1';
    }
    if (typeof nIncludeSmoking !== 'undefined') {
      sm = nIncludeSmoking === '1';
    }
    if (typeof nIncludePetFriendly !== 'undefined') {
      pf = nIncludePetFriendly === '1';
    }
    booking = {
      ...booking,
      hotel,
      sundrySales: sundrySales || [],
      checkIn,
      checkOut,
      flashCode: '',
      numberOfRooms,
      joinRemembered: user.isLoggedIn,
      supportedPayment: [oldBooking.payment_method],
      payment: oldBooking.payment_method,
      includeBreakfast: bf,
      includeSmoking: sm,
      includePetFriendly: pf,
      oldBooking,
    };
    let adults = 0;
    let children = 0;
    let firstGuest = null;
    let oldNumberOfRooms = 0;
    oldBooking.rooms.forEach(room => {
      room.guests.forEach(guest => {
        if (guest.type === 'Adult') {
          adults += 1;
        } else {
          children += 1;
        }
        if (!firstGuest) {
          firstGuest = {
            nameTitle: guest.name_title_id,
            firstName: guest.first_name,
            lastName: guest.last_name,
            country: guest.country_of_residence_code,
            preferLang: guest.prefer_lang,
            phoneCountry: guest.phoneCountry,
            email: guest.email,
          };
        }
      });
      oldNumberOfRooms += 1;
    });
    const roomTypes =
      (getState().booking &&
        getState().booking.hotel &&
        hotel.id === getState().booking.hotel.id &&
        hotel.is_birthday_remembered ===
          getState().booking.hotel.is_birthday_remembered &&
        checkIn.isSame(getState().booking.checkIn) &&
        checkOut.isSame(getState().booking.checkOut) &&
        !getState().booking.bookingDetail &&
        getState().booking &&
        adults === nAdults &&
        children === nChildren &&
        getState().booking.roomTypes) ||
      [];
    const roomTypeMapping = {};
    if (hotel.rooms.length && roomTypes.length === 0) {
      hotel.rooms.forEach(room => {
        const price = {
          normal: 0,
          normalNoTax: 0,
          adultNormal: 0,
          childNormal: 0,
          adultNormalNoTax: 0,
          childNormalNoTax: 0,

          member: 0,
          adultMember: 0,
          childMember: 0,
          memberNoTax: 0,
          adultMemberNoTax: 0,
          childMemberNoTax: 0,

          normalHotel: 0,
          adultNormalHotel: 0,
          childNormalHotel: 0,
          normalHotelNoTax: 0,
          adultNormalHotelNoTax: 0,
          childNormalHotelNoTax: 0,

          memberHotel: 0,
          adultMemberHotel: 0,
          childMemberHotel: 0,
          memberHotelNoTax: 0,
          adultMemberHotelNoTax: 0,
          childMemberHotelNoTax: 0,
        };
        room.rate.available_rates.forEach(rate => {
          price.normal += rate.selling_rate_non_member.price;
          price.adultNormal +=
            rate.selling_rate_non_member.additional_amount_adult;
          price.childNormal +=
            rate.selling_rate_non_member.additional_amount_child;
          price.normalNoTax += rate.selling_rate_non_member.price_no_tax;
          price.adultNormalNoTax +=
            rate.selling_rate_non_member.additional_amount_adult_no_tax;
          price.childNormalNoTax +=
            rate.selling_rate_non_member.additional_amount_child_no_tax;

          price.member += rate.selling_rate_member.price;
          price.adultMember += rate.selling_rate_member.additional_amount_adult;
          price.childMember += rate.selling_rate_member.additional_amount_child;
          price.memberNoTax += rate.selling_rate_member.price_no_tax;
          price.adultMemberNoTax +=
            rate.selling_rate_member.additional_amount_adult_no_tax;
          price.childMemberNoTax +=
            rate.selling_rate_member.additional_amount_child_no_tax;

          price.normalHotel += rate.selling_rate_non_member_pay_at_hotel.price;
          price.adultNormalHotel +=
            rate.selling_rate_non_member_pay_at_hotel.additional_amount_adult;
          price.childNormalHotel +=
            rate.selling_rate_non_member_pay_at_hotel.additional_amount_child;
          price.normalHotelNoTax +=
            rate.selling_rate_non_member_pay_at_hotel.price_no_tax;
          price.adultNormalHotelNoTax +=
            rate.selling_rate_non_member_pay_at_hotel.additional_amount_adult_no_tax;
          price.childNormalHotelNoTax +=
            rate.selling_rate_non_member_pay_at_hotel.additional_amount_child_no_tax;

          price.memberHotel += rate.selling_rate_member_pay_at_hotel.price;
          price.adultMemberHotel +=
            rate.selling_rate_member_pay_at_hotel.additional_amount_adult;
          price.childMemberHotel +=
            rate.selling_rate_member_pay_at_hotel.additional_amount_child;
          price.memberHotelNoTax +=
            rate.selling_rate_member_pay_at_hotel.price_no_tax;
          price.adultMemberHotelNoTax +=
            rate.selling_rate_member_pay_at_hotel.additional_amount_adult_no_tax;
          price.childMemberHotelNoTax +=
            rate.selling_rate_member_pay_at_hotel.additional_amount_child_no_tax;
        });
        const roomType = {
          room: JSON.parse(JSON.stringify(room)),
          limitGuest: room.rate.available_rates[0].limit_guest,
          numberOfRooms: 0,
          adults: 0,
          children: 0,
          price,
        };
        roomTypes.push(roomType);
        roomTypeMapping[roomType.room.id] = roomType;
      });
    }
    if (
      (nAdults === adults || !nAdults) &&
      (nChildren === children || !nChildren) &&
      (nNumberOfRooms === oldNumberOfRooms || !nNumberOfRooms)
    ) {
      oldBooking.rooms.forEach(room => {
        room.guests.forEach(guest => {
          if (roomTypeMapping[room.room_id]) {
            if (guest.type === 'Adult') {
              roomTypeMapping[room.room_id].adults += 1;
            } else {
              roomTypeMapping[room.room_id].children += 1;
            }
          }
        });
        if (roomTypeMapping[room.room_id]) {
          roomTypeMapping[room.room_id].numberOfRooms += 1;
        }
      });
    } else if (nAdults !== adults || nChildren !== children) {
      oldBooking.rooms.forEach(room => {
        if (roomTypeMapping[room.room_id]) {
          roomTypeMapping[room.room_id].adults = 0;
          roomTypeMapping[room.room_id].children = 0;
        }
      });
    }
    const profile = {
      ...firstGuest,
      birthday: user && user.user ? moment(user.user.birthday) : '',
    };
    booking = {
      ...booking,
      roomTypes,
      profile,
      adults: nAdults || adults,
      children: nChildren === null ? children : nChildren,
      bookingId: oldBookingId,
      bookingNumber: confirmationCode,
      email,
      oldCheckIn: moment(oldBooking.check_in),
      oldCheckOut: moment(oldBooking.check_out),
      oldRoomTypes: JSON.parse(JSON.stringify(roomTypeMapping)),
      oldAdults: adults,
      oldChildren: children,
      joinRemembered: user.isLoggedIn && oldBooking.is_owner,
    };
    dispatch({
      type: CREATE_BOOKING,
      payload: booking,
    });
    dispatch({
      type: USER_BOOKING,
    });
  };
}
