/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';
import cx from 'classnames';

import s from './Footer.css';
import Link from '../Link';
import Button from '../Button';
import { subscribeNewsletter } from '../../actions/user';
import {
  addFlashMessage,
  TYPE_OK,
  TYPE_ERROR,
} from '../../actions/flashMessage';

import iconFacebook from './icon-facebook.png';
import iconFacebookAdd2x from './icon-facebook@2x.png';
import iconInstagram from './icon-instagram.png';
import iconInstagramAdd2x from './icon-instagram@2x.png';
import { openModal as openNewsletterPopup } from '../NewsletterPopup';
import kayakLinks from '../../lib/kayak';

// image size discrepancy of webp and png is so small, used png to render on both Safari and other browsers

const messages = defineMessages({
  aboutRedPlanet: {
    id: 'footer.about_red_planet',
    defaultMessage: '##About Red Planet##',
    description: 'Footer about red planet header',
  },
  corporate: {
    id: 'footer.company',
    defaultMessage: '##Corporate##',
    description: 'Footer corporate link',
  },
  contact: {
    id: 'footer.contact',
    defaultMessage: '##Contact Us##',
    description: 'Footer contact link',
  },
  loyaltyProgram: {
    id: 'footer.loyalty_program',
    defaultMessage: '##Blog Program##',
    description: 'Footer loyalty program header',
  },
  subscribe: {
    id: 'footer.subscribe',
    defaultMessage: '##Sign Up##',
    description: 'Footer loyalty program subscribe link',
  },
  benefits: {
    id: 'footer.benefits',
    defaultMessage: '##Benefits##',
    description: 'Footer loyalty program benefits link',
  },
  promotions: {
    id: 'footer.promotions',
    defaultMessage: '##Promotion##',
    description: 'Footer loyalty program promotion link',
  },
  news: {
    id: 'footer.news',
    defaultMessage: '##News##',
    description: 'Footer loyalty program news link',
  },
  career: {
    id: 'footer.career',
    defaultMessage: '##Career##',
    description: 'Footer career link',
  },
  newsletter: {
    id: 'footer.newsletter',
    defaultMessage: '##Newsletter##',
    description: 'Footer loyalty program header',
  },
  discounts: {
    id: 'footer.discounts',
    defaultMessage: '##Discounts##',
    description: 'Footer discounts link',
  },
  newsletterButton: {
    id: 'footer.newsletter_button',
    defaultMessage: 'SIGN ME UP',
    description: 'Newsletter subscription button',
  },
  newsletterButtonLabel: {
    id: 'footer.newsletter_button_label',
    defaultMessage: 'GET MORE DEALS AND UPDATES',
    description: 'Label on Newsletter subscription button',
  },
  termsAndConditions: {
    id: 'footer.t_and_c',
    defaultMessage: '##Terms & Conditions##',
    description: 'Terms & Conditions link in footer',
  },
  privacy: {
    id: 'footer.privacy',
    defaultMessage: '##Privacy##',
    description: 'Privacy link in footer',
  },
  blog: {
    id: 'footer.blog',
    defaultMessage: '##BLOG##',
    description: 'Blog link in footer',
  },
  policy: {
    id: 'footer.policy',
    defaultMessage: '##Policy##',
    description: 'Policy link in footer',
  },
  termsOfUse: {
    id: 'footer.term_of_use',
    defaultMessage: '##Terms of Use##',
    description: 'Terms of use link in footer',
  },
  followUs: {
    id: 'footer.follow_us',
    defaultMessage: '##Follow Us!##',
    description: 'Follow us section header on footer',
  },
  copyRight: {
    id: 'footer.copy_right',
    defaultMessage: '##{year}© RedPlanetHotels.com##',
    description: 'Copyright on footer',
  },
  socialMedialDescription: {
    id: 'footer.ask_and_share',
    defaultMessage:
      'Ask and share on our social media pages with red planet hotels community!',
    description: 'Social media description on footer',
  },
  yourEmailHasBeenSubscribed: {
    id: 'footer.yourEmailHasBeenSubscribed',
    defaultMessage: '##Your email has been subscribed##',
    description: 'Message when user success subscribe for newsletter',
  },
  failEmailSubscribtion: {
    id: 'footer.failEmailSubscribtion',
    defaultMessage: "Can't subscribe your email",
    description: 'Message when user fail subscribe for newsletter',
  },
  faqs: {
    id: 'footer.faqs',
    defaultMessage: 'Faqs',
    description: 'Faqs link in footer',
  },
});

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      kayakData: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.news && this.props.news && this.props.news.starting) {
      const { starting, success } = nextProps.news;
      if (!starting && success) {
        this.props.addFlashMessage(
          this.context.intl.formatMessage(messages.yourEmailHasBeenSubscribed),
          TYPE_OK,
        );
      }
      if (!starting && !success) {
        this.props.addFlashMessage(
          this.context.intl.formatMessage(messages.failEmailSubscribtion),
          TYPE_ERROR,
        );
      }
    }

    if (window.location.pathname.split('/')[1] === 'hotel') {
      this.setState({
        kayakData: kayakLinks.find(e => e.name === nextProps.hotel.code),
      });
    } else {
      this.setState({ kayakData: null });
    }
  }

  render() {
    const { noMobileFooter } = this.props;
    const { kayakData } = this.state;
    const supportedLocale = ['en', 'ja', 'th', 'id'];
    const locale =
      supportedLocale.indexOf(this.context.intl.locale) > -1
        ? this.context.intl.locale
        : 'en';
    return (
      <div
        className={cx(
          s.root,
          noMobileFooter && s.mobileHidden,
          s[this.context.intl.locale],
        )}
      >
        <div className={s.container}>
          <ul className={s.outerList}>
            <li>
              <h2>
                <FormattedMessage {...messages.aboutRedPlanet} />
              </h2>
              <ul className={s.nestedList}>
                <li>
                  <a
                    href="https://redplanethospitality.com/"
                    hrefLang={this.context.intl.locale}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage {...messages.corporate} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://redplanethospitality.com/news"
                    hrefLang={this.context.intl.locale}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage {...messages.news} />
                  </a>
                </li>
                <li>
                  <a
                    href={`https://corporate.redplanethotels.com/${locale}/careers`}
                    hrefLang={this.context.intl.locale}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage {...messages.career} />
                  </a>
                </li>
                <li>
                  <Link to="/contact">
                    <FormattedMessage {...messages.contact} />
                  </Link>
                </li>
              </ul>
            </li>
            <li className={s.hideMobile}>
              <h2>
                <FormattedMessage {...messages.loyaltyProgram} />
              </h2>
              <ul className={s.nestedList}>
                <li>
                  <Link to="/sign-up">
                    <FormattedMessage {...messages.subscribe} />
                  </Link>
                </li>
                <li>
                  <Link to="/loyalty">
                    <FormattedMessage {...messages.benefits} />
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <h2>
                <FormattedMessage {...messages.newsletter} />
              </h2>
              <h3 className={s.newsletterButtonLabel}>
                <FormattedMessage {...messages.newsletterButtonLabel} />
              </h3>
              <Button
                className={cx(s.newsletterButton, s[this.context.intl.locale])}
                onClick={this.props.openNewsletterPopup}
                label={this.context.intl.formatMessage({
                  ...messages.newsletterButton,
                })}
              />
            </li>
          </ul>
          <i className={s.logo} />
        </div>
        <div className={s.containerFluid}>
          <div className={s.body}>
            <div className={s.clear}>
              <ul className={s.terms}>
                <li>
                  <Link to="/conditions">
                    <FormattedMessage {...messages.termsAndConditions} />
                  </Link>
                </li>
                <li>
                  <Link to="/faq">
                    <FormattedMessage {...messages.faqs} />
                  </Link>
                </li>
                <li>
                  <Link to="/blog">
                    <FormattedMessage {...messages.blog} />
                  </Link>
                </li>
                <li>
                  <Link to="/policy">
                    <FormattedMessage {...messages.privacy} />
                  </Link>
                </li>
                <li>
                  <Link to="/terms">
                    <FormattedMessage {...messages.termsOfUse} />
                  </Link>
                </li>
              </ul>
              {kayakData && (
                <ul className={s.kayak}>
                  <li>
                    <a
                      href={kayakData.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="kayak link"
                    >
                      <img
                        height="15"
                        src={kayakData.image}
                        alt=""
                        className={s.iconKayak}
                      />
                    </a>
                  </li>
                </ul>
              )}
              <ul className={s.followUs}>
                <li className={s.followUsHeader}>
                  <FormattedMessage {...messages.followUs} />
                </li>
                <li>
                  <a
                    href="https://taps.io/fbfooter"
                    hrefLang={this.context.intl.locale}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className={s.facebook}
                      src={iconFacebook}
                      srcSet={`${iconFacebookAdd2x} 2x`}
                      alt="facebook"
                      width="19px"
                      height="41px"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://taps.io/instagramfooter"
                    hrefLang={this.context.intl.locale}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className={s.instagram}
                      src={iconInstagram}
                      srcSet={`${iconInstagramAdd2x} 2x`}
                      alt="instagram"
                      width="29px"
                      height="29px"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className={s.clear}>
              <span className={cx(s.description)}>
                © Red Planet Philippines Services Corp.
              </span>
            </div>
            <div className={s.clear}>
              <div>&nbsp;</div>
              <div className={cx(s.social, s.description, s.pullRight)}>
                <FormattedMessage {...messages.socialMedialDescription} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  news: state.user.news,
  hotel: state.hotel,
});

const mapDispatch = {
  subscribeNewsletter,
  addFlashMessage,
  openNewsletterPopup,
};

Footer.propTypes = {
  noMobileFooter: PropTypes.bool,
  addFlashMessage: PropTypes.func,
  news: PropTypes.shape({
    starting: PropTypes.bool,
    success: PropTypes.bool,
  }),
  openNewsletterPopup: PropTypes.func,
  hotel: PropTypes.shape({
    code: PropTypes.string,
  }),
};

Footer.contextTypes = {
  intl: intlShape.isRequired,
};

Footer.defaultProps = {
  noMobileFooter: false,
  addFlashMessage: () => {},
  news: {
    starting: false,
    success: false,
  },
  openNewsletterPopup: () => {},
  hotel: {},
};

export default connect(mapState, mapDispatch)(withStyles(s)(Footer));
