import {
  GraphQLObjectType as ObjectType,
  GraphQLString as StringType,
  GraphQLInt as IntType,
  GraphQLNonNull as NonNull,
  GraphQLList as List,
  GraphQLFloat as FloatType,
  GraphQLBoolean as BooleanType,
} from 'graphql';

import PropTypes from 'prop-types';

import RoomType from './RoomType';
import PromotionType from './PromotionType';

const HotelType = new ObjectType({
  name: 'Hotel',
  fields: {
    id: { type: IntType },
    name: { type: new NonNull(StringType) },
    name_en: { type: new NonNull(StringType) },
    default_image: { type: new NonNull(StringType) },
    slug_name: { type: new NonNull(StringType) },
    seekda_id: { type: StringType },
    helpshift_tag: { type: new NonNull(StringType) },
    description: { type: new NonNull(StringType) },
    code: { type: new NonNull(StringType) },
    address: { type: new NonNull(StringType) },
    phone: { type: new NonNull(StringType) },
    email: { type: new NonNull(StringType) },
    is_birthday_remembered: { type: new NonNull(BooleanType) },
    country_code: { type: new NonNull(StringType) },
    checkin_time: { type: new NonNull(StringType) },
    payment_method: {
      type: new List(StringType),
    },
    payment_method_maya: { type: new List(StringType) },
    features: {
      type: new List(
        new ObjectType({
          name: 'HotelFeatures',
          fields: {
            id: { type: new NonNull(IntType) },
            code: { type: new NonNull(StringType) },
            name: { type: new NonNull(StringType) },
          },
        }),
      ),
    },
    usp: {
      type: new List(
        new ObjectType({
          name: 'USP',
          fields: {
            id: { type: new NonNull(IntType) },
            icon: { type: new NonNull(StringType) },
            description: { type: new NonNull(StringType) },
          },
        }),
      ),
    },
    breakfast: {
      type: new ObjectType({
        name: 'Breakfast',
        fields: {
          name: { type: new NonNull(StringType) },
          price_per_guest: { type: new NonNull(FloatType) },
          price_per_guest_no_tax: { type: new NonNull(FloatType) },
        },
      }),
    },
    smoking: {
      type: new ObjectType({
        name: 'Smoking',
        fields: {
          hotel_id: { type: new NonNull(StringType) },
          price_per_night: { type: new NonNull(FloatType) },
        },
      }),
    },
    pet_friendly: {
      type: new ObjectType({
        name: 'PetFriendly',
        fields: {
          hotel_id: { type: new NonNull(StringType) },
          price_per_night: { type: new NonNull(FloatType) },
        },
      }),
    },
    images: {
      type: new List(new NonNull(StringType)),
    },
    location: {
      type: new ObjectType({
        name: 'HotelLocation',
        fields: {
          id: {
            type: new NonNull(IntType),
          },
          display_name: {
            type: new NonNull(StringType),
          },
          parent: {
            type: new NonNull(StringType),
          },
        },
      }),
    },
    start_price: { type: new NonNull(IntType) },
    start_price_remembered: { type: new NonNull(IntType) },
    currency: { type: new NonNull(StringType) },
    opening_date: { type: StringType },
    opening_soon: { type: new NonNull(BooleanType) },
    check_in: { type: StringType },
    check_out: { type: StringType },
    coordinate: { type: new NonNull(StringType) },
    rooms: {
      type: new List(RoomType),
    },
    flash_sale: {
      type: PromotionType,
    },
    user_contents: {
      type: new ObjectType({
        name: 'HotelUserContent',
        fields: {
          info: {
            type: new ObjectType({
              name: 'HotelUserContentInfo',
              fields: {
                number_of_reviews: { type: new NonNull(IntType) },
                total_reviews: { type: IntType },
                rating: { type: new NonNull(FloatType) },
                comment: { type: new NonNull(StringType) },
              },
            }),
          },
          reviews: {
            type: new List(
              new ObjectType({
                name: 'HotelUserContentReview',
                fields: {
                  title: { type: new NonNull(StringType) },
                  message: { type: new NonNull(StringType) },
                  rating: { type: new NonNull(FloatType) },
                  rating_image_url: { type: new NonNull(StringType) },
                  is_machine_translated: { type: new NonNull(StringType) },
                  review_at: { type: new NonNull(StringType) },
                  review_url: { type: new NonNull(StringType) },
                  user: {
                    type: new ObjectType({
                      name: 'HotelUserContentAuthor',
                      fields: {
                        username: { type: new NonNull(StringType) },
                        avatar_large_url: { type: new NonNull(StringType) },
                      },
                    }),
                  },
                },
              }),
            ),
          },
          tags: {
            type: new List(
              new ObjectType({
                name: 'HotelUserContentTag',
                fields: {
                  tag: { type: new NonNull(StringType) },
                  score: { type: new NonNull(IntType) },
                  mention_count: { type: new NonNull(IntType) },
                },
              }),
            ),
          },
        },
      }),
    },
    policy_guarantee: {
      type: new ObjectType({
        name: 'PolicyGuarantee',
        fields: {
          pay_now_rules: { type: new NonNull(StringType) },
          pay_later_rules: { type: new NonNull(StringType) },
          expire_time: { type: new NonNull(StringType) },
        },
      }),
    },
  },
});

HotelType.shape = () =>
  PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    code: PropTypes.string,
    address: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    breakfast: PropTypes.shape({
      name: PropTypes.string,
      price_per_guest: PropTypes.number,
      price_per_guest_no_tax: PropTypes.number,
    }),
    default_image: PropTypes.string,
    slug_name: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    location: PropTypes.shape({
      display_name: PropTypes.string,
    }),
    start_price_remembered: PropTypes.number,
    currency: PropTypes.string,
    coordinate: PropTypes.string,
    rooms: PropTypes.arrayOf(RoomType.shape()),
  });

export default HotelType;
